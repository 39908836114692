import { Label } from "./ui/label";
import { FormItem, FormMessage } from "./ui/form";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { FormProvider } from "react-hook-form";
import { FormContainer } from "../Styles";
import {
  AccountDetailsContext,
  AccountDetailsTitle,
  AccountDetailsTitleDescription,
  AccountMainContainer,
} from "./Styles";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

type BusinesDetailsProps = {
  methods: any;
  handleChange: any;
  userData: any;
  errors: any;
  countriesIsLoading: boolean;
  handleCountrySelect: (event: any) => void;
  countries: any;
  handleBusinessDataSubmit: () => void;
  updateBusinessDataMutation: any;
  businessData: any;
  disbaleSaveChanges: boolean;
  businessTypes: any;
  businessTypesIsLoading: boolean;
  handleBusinessTypeSelect: any;
};

export default function BusinesDetails({
  methods,
  handleChange,
  userData,
  errors,
  countriesIsLoading,
  handleCountrySelect,
  countries,
  handleBusinessDataSubmit,
  updateBusinessDataMutation,
  businessData,
  disbaleSaveChanges,
  businessTypesIsLoading,
  businessTypes,
  handleBusinessTypeSelect,
}: BusinesDetailsProps) {
  return (
    <AccountMainContainer>
      <AccountDetailsContext>
        <AccountDetailsTitle>Business Account</AccountDetailsTitle>
        <AccountDetailsTitleDescription>
          Update your business details here.
        </AccountDetailsTitleDescription>
      </AccountDetailsContext>

      <FormContainer>
        <FormProvider {...methods}>
          <FormItem>
            <Label>Name</Label>
            <Input
              name="name"
              placeholder="eg: John Doe"
              onChange={handleChange}
              defaultValue={businessData?.name || ""}
            />
            {errors?.name && <FormMessage>{errors?.nameErrorMessage}</FormMessage>}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Description</Label>
            <Input
              type="text"
              name="description"
              onChange={handleChange}
              defaultValue={businessData?.description || ""}
            />
            {errors?.description && <FormMessage>{errors?.descriptionErrorMessage}</FormMessage>}
          </FormItem>

          <FormItem className="mt-3">
            <Label>Business type</Label>

            <Select
              disabled={businessTypesIsLoading}
              onValueChange={(event) => handleBusinessTypeSelect(event)}
              defaultValue={businessData?.type ?? ""}
            >
              <SelectTrigger
                className="w-full"
                onChange={(event: any) => handleBusinessTypeSelect(event)}
              >
                <SelectValue placeholder="select business type" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {businessTypes?.map(({ name, id }: { name: string; id: string }) => (
                    <SelectItem key={id} value={id}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormItem>

          <FormItem className="mt-5">
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              defaultValue={businessData?.email || ""}
            />
            {errors?.email && <FormMessage>{errors?.emailErrorMessage}</FormMessage>}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Phone number</Label>
            <Input
              type="tel"
              name="phone"
              onChange={handleChange}
              defaultValue={userData?.phone || ""}
            />
            {errors?.phone && <FormMessage>{errors?.phoneErrorMessage}</FormMessage>}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Country</Label>

            <Select
              disabled={countriesIsLoading}
              onValueChange={(event) => handleCountrySelect(event)}
            >
              <SelectTrigger
                className="w-full"
                onChange={(event: any) => handleCountrySelect(event.target.value)}
              >
                <SelectValue placeholder="select country" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {countries?.map(({ name, code }: { name: string; code: string }) => (
                    <SelectItem key={`${name}-${code}`} value={name}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormItem>

          <FormItem className="mt-5">
            <Label>First director name</Label>
            <Input
              name="id_director_one"
              placeholder="eg: John Doe"
              onChange={handleChange}
              defaultValue={businessData?.id_director_one || ""}
            />
            {errors?.id_director_one && (
              <FormMessage>{errors?.idDirectorOneErrorMessage}</FormMessage>
            )}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Second director name</Label>
            <Input
              name="id_director_two"
              placeholder="eg: John Doe"
              onChange={handleChange}
              defaultValue={businessData?.id_director_two || ""}
            />
            {errors?.id_director_two && (
              <FormMessage>{errors?.idDirectorTwoErrorMessage}</FormMessage>
            )}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Address</Label>
            <Input
              type="text"
              name="address"
              onChange={handleChange}
              defaultValue={businessData?.address || ""}
            />
            {errors?.address && <FormMessage>{errors?.addressErrorMessage}</FormMessage>}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Size</Label>
            <Input
              type="number"
              name="size"
              min={1}
              onChange={handleChange}
              defaultValue={businessData?.size || ""}
            />
            {errors?.size && <FormMessage>{errors?.sizeErrorMessage}</FormMessage>}
          </FormItem>

          <FormItem className="mt-5">
            <Label>Monthly transaction volume</Label>
            <Input
              type="text"
              name="transaction_volume"
              onChange={handleChange}
              defaultValue={businessData?.transaction_volume || ""}
            />
            {errors?.transaction_volume && (
              <FormMessage>{errors?.transactionVolumeErrorMessage}</FormMessage>
            )}
          </FormItem>

          <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
            <Button
              type="submit"
              onClick={handleBusinessDataSubmit}
              disabled={updateBusinessDataMutation.isPending || disbaleSaveChanges}
              className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
            >
              {updateBusinessDataMutation.isPending ? "updating..." : "Save changes"}
            </Button>
          </div>
        </FormProvider>
      </FormContainer>
    </AccountMainContainer>
  );
}
