import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./App.css";
import RouteWrapper from "./RouteWrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "./components/common/ui/toaster";
import { isMobile } from "react-device-detect";
const queryClient = new QueryClient();

console.log("isMobile: ", isMobile);

function App() {
  return (
    <div className="App">
      {isMobile ? (
        <h6
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
            color: "#707070",
            textAlign: "center",
          }}
        >
          This content is temporarily not available in mobile view.
        </h6>
      ) : (
        <QueryClientProvider client={queryClient}>
          <RouteWrapper />
          <ToastContainer />
          <ReactQueryDevtools />
          <Toaster />
        </QueryClientProvider>
      )}
    </div>
  );
}
export default App;
