import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import { useNavigate } from "react-router-dom";

import "../custom-styles.css";

const CompleteActivation = ({
  open,
  description,
  handleClose,
}: {
  open: boolean;
  description: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Complete Activation</Dialog.Title>

          <div className="grid w-full max-w-sm items-center gap-1.5 m-1rem mt-5">
            <Label htmlFor="amount" className="text-sm ">
              {description}
            </Label>
          </div>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}></div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={handleClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <button
                onClick={() => navigate("/account")}
                type="submit"
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Complete Activation
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button onClick={handleClose} className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CompleteActivation;
