import React, { useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useCreateTradeRequestMutationApi } from "src/hooks/useTrading";
import { Button } from "./button";
import { TitleDescription } from "src/components/Styles";
import { useCreateBusinessTradeRequestMutationApi } from "src/hooks/useB2bTrading";

const ConfirmDeleteModal = ({
  open,
  handleClose,
  request,
  businessId,
}: {
  open: boolean;
  handleClose: () => void;
  request: any;
  businessId?: any;
}) => {
  const { deleteRequest } = useCreateTradeRequestMutationApi();
  const { deleteBusinessRequest } = useCreateBusinessTradeRequestMutationApi();

  function handleDelete() {
    if (businessId) {
      deleteBusinessRequest.mutate(request?.id);
    } else {
      deleteRequest.mutate(request?.id);
    }
  }

  useEffect(() => {
    if ((deleteBusinessRequest.isSuccess, deleteRequest.isSuccess)) handleClose();
  }, [deleteBusinessRequest.isSuccess, deleteRequest.isSuccess]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay style={{ inset: 1000 }} className="DialogOverlay">
          <Dialog.Content style={{ width: 480, height: 300 }} className="DialogContent">
            <Dialog.Title style={{ marginBottom: 24 }} className="DialogTitle">
              Delete request?
            </Dialog.Title>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close" onClick={handleClose}>
                <Cross2Icon />
              </button>
            </Dialog.Close>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 20,
                height: "60%",
              }}
            >
              <TitleDescription> Are you sure you want to delete this request?</TitleDescription>
              <Button
                type="submit"
                onClick={handleDelete}
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Yes, Delete
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConfirmDeleteModal;
