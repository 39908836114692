// import { Oval } from "react-loader-spinner";

import { Loader2 } from "lucide-react";

const LoadingIndicator = () => {
  return (
    <div className="flex items-center justify-center animate-spin">
      <Loader2 height={70} width={70} color="#B52C2C" />
    </div>
  );
};

export default LoadingIndicator;
