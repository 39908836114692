import { useState } from "react";
import { useMutation, useQuery, useIsMutating } from "@tanstack/react-query";

import { Button } from "./common/ui/button";
import Dropzone from "./common/Dropzone";
import { uploadKYCDoc } from "../services/index";
import { getUser } from "../services/index";
import { useToast } from "./common/ui/use-toast";
import { CheckIcon } from "../assests/svgs/CheckIcon";
import { REACT_APP_BASE_URL } from "src/helpers/constants";
import { CheckIconContainer, UpdateUploadContainer } from "./Styles";
import {
  AccountDetailsContext,
  AccountDetailsTitle,
  AccountDetailsTitleDescription,
  AccountMainContainer,
} from "./common/Styles";

const KYCVerification = () => {
  const { toast } = useToast();
  const isLoading = useIsMutating();

  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [toggleProfilePicture, setToggleProfilePicture] = useState<boolean>(false);
  const [toggleIDUpload, setToggleIDUpload] = useState<boolean>(false);

  const { data: userData, refetch: refetchUserData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const userId = userData?.id;

  const profilePictureMutation = useMutation({
    mutationKey: ["profiilePicture"],
    mutationFn: (formData: FormData) => uploadKYCDoc(userId, formData),
    onSuccess: async () => {
      await refetchUserData();
      setToggleProfilePicture(false);
      setIsFinished(true);
      toast({
        description: "Profile picture uploaded successfully.",
      });
    },
    onError: () => {
      setIsFinished(true);
      setToggleProfilePicture(false);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload profile picture.",
      });
    },
  });

  const identificationDocumentMutation = useMutation({
    mutationFn: (formData: FormData) => uploadKYCDoc(userId, formData),
    onSuccess: async () => {
      await refetchUserData();
      setToggleIDUpload(false);
      setIsFinished(true);
      toast({
        description: "Identification document uploaded successfully.",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload identification document.",
      });
      setIsFinished(true);
    },
  });

  const handleFileUpload = (formData: FormData) => {
    setIsFinished(false);
    profilePictureMutation.mutate(formData);
  };

  const handleDocUpload = (formData: FormData) => {
    setIsFinished(false);
    identificationDocumentMutation.mutate(formData);
  };

  return (
    <>
      <div>
        <AccountMainContainer>
          <AccountDetailsContext>
            <AccountDetailsTitle> Profile Picture</AccountDetailsTitle>{" "}
            <AccountDetailsTitleDescription>
              Update your profile picture here.
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>

          <UpdateUploadContainer>
            {!userData?.user_images?.profile_picture || toggleProfilePicture ? (
              <Dropzone
                onFileUpload={handleFileUpload}
                isLoading={isLoading > 0}
                id="profile_picture"
                isFinished={isFinished}
              />
            ) : (
              <div style={{ alignItems: "center", textAlign: "center" }}>
                <img
                  src={`${REACT_APP_BASE_URL}${userData?.user_images?.profile_picture}`}
                  alt="profile_picture"
                  height={100}
                  width={100}
                />

                <AccountDetailsTitleDescription style={{ marginTop: "0.5rem", textAlign: "left" }}>
                  Your recently uploaded picture
                </AccountDetailsTitleDescription>
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (
                        userData &&
                        userData.user_images &&
                        userData.user_images.profile_picture
                      ) {
                        window.open(
                          `${REACT_APP_BASE_URL}${userData.user_images.profile_picture}`,
                          "_blank",
                        );
                      }
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setToggleProfilePicture(true)}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400  ml-2"
                  >
                    Update Upload
                  </Button>
                </div>
              </div>
            )}
          </UpdateUploadContainer>
        </AccountMainContainer>
      </div>

      <div>
        <AccountMainContainer>
          <AccountDetailsContext>
            <AccountDetailsTitle> Identification Document Upload</AccountDetailsTitle>{" "}
            <AccountDetailsTitleDescription>
              Upload legible copy of your government-issued identification document (e.g., passport,
              national ID card)
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>

          <UpdateUploadContainer>
            {!userData?.user_images?.user_id_image || toggleIDUpload ? (
              <Dropzone
                onFileUpload={handleDocUpload}
                isLoading={isLoading > 0}
                id="user_id_image"
                isFinished={isFinished}
              />
            ) : (
              <div style={{ alignItems: "center", textAlign: "center" }}>
                <CheckIconContainer>
                  <CheckIcon fill="white" width={20} height={20} />
                </CheckIconContainer>
                <AccountDetailsTitleDescription style={{ marginTop: "0.5rem", textAlign: "left" }}>
                  You have successfully uploaded an Identification Document.
                </AccountDetailsTitleDescription>
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (userData && userData.user_images && userData.user_images.user_id_image) {
                        window.open(
                          `${REACT_APP_BASE_URL}${userData?.user_images?.user_id_image}`,
                          "_blank",
                        );
                      }
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setToggleIDUpload(true)}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400  ml-2"
                  >
                    Update Upload
                  </Button>
                </div>
              </div>
            )}
          </UpdateUploadContainer>
        </AccountMainContainer>
      </div>
    </>
  );
};

export default KYCVerification;
