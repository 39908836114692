import { useState } from "react";
import { useMutation, useIsMutating } from "@tanstack/react-query";
import Dropzone from "./Dropzone";
import { Button } from "./ui/button";
import { REACT_APP_BASE_URL } from "src/helpers/constants";
import { CheckIcon } from "src/assests/svgs/CheckIcon";
import { CheckIconContainer } from "../Styles";
import { useToast } from "./ui/use-toast";
import { useQuery } from "@tanstack/react-query";

import { uploadBusinessDocs, getSelectedBusiness } from "src/services";
import {
  AccountDetailsContext,
  AccountDetailsTitle,
  AccountDetailsTitleDescription,
  AccountMainContainer,
} from "./Styles";

type BusinessDocumentsProps = {
  id: string | undefined;
};

export default function BusinessDocuments({ id }: BusinessDocumentsProps) {
  const { toast } = useToast();
  const isLoading = useIsMutating();

  const [toggleRegistrationDocument, setToggleRegistrationDocument] = useState<boolean>(false);
  const [toggleDirectorI, setToggleDirectorI] = useState<boolean>(false);
  const [toggleDirectorII, setToggleDirectorII] = useState<boolean>(false);
  const [toggleLogo, setToggleLogo] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const { data: selectedBusinessData, refetch: refetchSelectedBusinsessData } = useQuery({
    queryKey: ["selectedBusiness"],
    queryFn: () => getSelectedBusiness(id),
    enabled: !!id,
  });

  const registrationDocumentMutation = useMutation({
    mutationKey: ["registrationDoc"],
    mutationFn: (formData: FormData) => uploadBusinessDocs(id, formData),
    onSuccess: async () => {
      await refetchSelectedBusinsessData();
      setIsFinished(true);
      setToggleRegistrationDocument(false);
      toast({
        description: "Registration uploaded successfully.",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload registration document.",
      });
    },
  });

  const firstDirectorIdentificationMutation = useMutation({
    mutationFn: (formData: FormData) => uploadBusinessDocs(id, formData),
    onSuccess: async () => {
      await refetchSelectedBusinsessData();
      setIsFinished(true);
      setToggleDirectorI(false);
      toast({
        description: "First director identification document uploaded successfully.",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload first director identification document.",
      });
    },
  });

  const secondDirectorIdentificationMutation = useMutation({
    mutationFn: (formData: FormData) => uploadBusinessDocs(id, formData),
    onSuccess: async () => {
      await refetchSelectedBusinsessData();
      setToggleDirectorII(false);
      setIsFinished(true);
      toast({
        description: "Second director identification document uploaded successfully.",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload second director identification document.",
      });
    },
  });

  const logoMutation = useMutation({
    mutationKey: ["logo"],
    mutationFn: (formData: FormData) => uploadBusinessDocs(id, formData),
    onSuccess: async () => {
      await refetchSelectedBusinsessData();
      setIsFinished(true);
      setToggleLogo(false);
      toast({
        description: "Business logo uploaded successfully.",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to upload business logo.",
      });
    },
  });

  const handleRegistrationDocumentUpload = (formData: FormData) => {
    setIsFinished(false);
    registrationDocumentMutation.mutate(formData);
  };

  const handleFirstDirectorIdentificationDocumentUpload = (formData: FormData) => {
    setIsFinished(false);
    firstDirectorIdentificationMutation.mutate(formData);
  };

  const handleSecondDirectorIdentificationDocumentUpload = (formData: FormData) => {
    setIsFinished(false);
    secondDirectorIdentificationMutation.mutate(formData);
  };

  const logoUpload = (formData: FormData) => {
    setIsFinished(false);
    logoMutation.mutate(formData);
  };

  return (
    <>
      <div>
        <AccountMainContainer>
          <AccountDetailsContext>
            <AccountDetailsTitle> Registration Document</AccountDetailsTitle>{" "}
            <AccountDetailsTitleDescription>
              Upload your business' registration document here.
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            {!selectedBusinessData?.business_documents?.registration_documents ||
            toggleRegistrationDocument ? (
              <Dropzone
                onFileUpload={handleRegistrationDocumentUpload}
                isLoading={isLoading > 0}
                id="registration_documents"
                isFinished={isFinished}
              />
            ) : (
              <div style={{ alignItems: "center", textAlign: "center" }}>
                <CheckIconContainer>
                  <CheckIcon fill="white" width={20} height={20} />
                </CheckIconContainer>
                <AccountDetailsTitleDescription style={{ marginTop: "0.5rem", textAlign: "left" }}>
                  You have successfully uploaded a registration document.
                </AccountDetailsTitleDescription>
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (selectedBusinessData?.business_documents?.registration_documents) {
                        window.open(
                          `${REACT_APP_BASE_URL}${selectedBusinessData?.business_documents?.registration_documents}`,
                          "_blank",
                        );
                      }
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setToggleRegistrationDocument(true)}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400  ml-2"
                  >
                    Update Upload
                  </Button>
                </div>
              </div>
            )}
          </div>
        </AccountMainContainer>
      </div>

      <div>
        <AccountMainContainer>
          <AccountDetailsContext>
            <AccountDetailsTitle>Director I Identification Image</AccountDetailsTitle>{" "}
            <AccountDetailsTitleDescription>
              Upload legible copy of a government-issued identification document of a director of
              the business (e.g., passport, national ID card)
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            {!selectedBusinessData?.business_documents?.id_image_director_one || toggleDirectorI ? (
              <Dropzone
                onFileUpload={handleFirstDirectorIdentificationDocumentUpload}
                isLoading={isLoading > 0}
                id="id_image_director_one"
              />
            ) : (
              <div style={{ alignItems: "center", textAlign: "center" }}>
                <CheckIconContainer>
                  <CheckIcon fill="white" width={20} height={20} />
                </CheckIconContainer>
                <AccountDetailsTitleDescription style={{ marginTop: "0.5rem", textAlign: "left" }}>
                  You have successfully uploaded Director I Identification Image.
                </AccountDetailsTitleDescription>
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (selectedBusinessData?.business_documents?.id_image_director_one) {
                        window.open(
                          `${REACT_APP_BASE_URL}${selectedBusinessData?.business_documents?.id_image_director_one}`,
                          "_blank",
                        );
                      }
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setToggleDirectorI(true)}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400  ml-2"
                  >
                    Update Upload
                  </Button>
                </div>
              </div>
            )}
          </div>
        </AccountMainContainer>
      </div>

      <div>
        <AccountMainContainer>
          <AccountDetailsContext>
            <AccountDetailsTitle>Director II Identification Image</AccountDetailsTitle>{" "}
            <AccountDetailsTitleDescription>
              Upload legible copy of a government-issued identification document of another director
              of the business (e.g., passport, national ID card)
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            {!selectedBusinessData?.business_documents?.id_image_director_two ||
            toggleDirectorII ? (
              <Dropzone
                onFileUpload={handleSecondDirectorIdentificationDocumentUpload}
                isLoading={isLoading > 0}
                id="id_image_director_two"
              />
            ) : (
              <div style={{ alignItems: "center", textAlign: "center" }}>
                <CheckIconContainer>
                  <CheckIcon fill="white" width={20} height={20} />
                </CheckIconContainer>
                <AccountDetailsTitleDescription style={{ marginTop: "0.5rem", textAlign: "left" }}>
                  You have successfully uploaded Director II Identification Image.
                </AccountDetailsTitleDescription>
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (selectedBusinessData?.business_documents?.id_image_director_two) {
                        window.open(
                          `${REACT_APP_BASE_URL}${selectedBusinessData?.business_documents?.id_image_director_two}`,
                          "_blank",
                        );
                      }
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setToggleDirectorII(true)}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400  ml-2"
                  >
                    Update Upload
                  </Button>
                </div>
              </div>
            )}
          </div>
        </AccountMainContainer>
      </div>

      <div>
        <AccountMainContainer>
          <AccountDetailsContext>
            <AccountDetailsTitle>Business Logo</AccountDetailsTitle>{" "}
            <AccountDetailsTitleDescription>
              Upload your business logo here.
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            {!selectedBusinessData?.business_documents?.logo || toggleLogo ? (
              <Dropzone onFileUpload={logoUpload} isLoading={isLoading > 0} id="logo" />
            ) : (
              <div style={{ alignItems: "center", textAlign: "center" }}>
                <CheckIconContainer>
                  <CheckIcon fill="white" width={20} height={20} />
                </CheckIconContainer>
                <AccountDetailsTitleDescription style={{ marginTop: "0.5rem", textAlign: "left" }}>
                  You have successfully uploaded business logo.
                </AccountDetailsTitleDescription>
                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (selectedBusinessData?.business_documents?.logo) {
                        window.open(
                          `${REACT_APP_BASE_URL}${selectedBusinessData?.business_documents?.logo}`,
                          "_blank",
                        );
                      }
                    }}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => setToggleLogo(true)}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400  ml-2"
                  >
                    Update Upload
                  </Button>
                </div>
              </div>
            )}
          </div>
        </AccountMainContainer>
      </div>
    </>
  );
}
