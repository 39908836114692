import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "src/hooks/useAuth";

type UserData = {
  email: string;
  password: string;
};

export function useSignIn() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL || "https://account-ms.test.hanypay.co";

  const userSignIn = async (userData: UserData) => {
    try {
      const response = await fetch(`${BASE_URL}/auth/login/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (data?.otp_page_redirect) {
        navigate("/otp-verify", { replace: true, state: { user_id: data.user_id } });
        throw new Error(
          data.message ||
            "OTP not verified. A new OTP has been sent to your email. Please verify to proceed",
        );
      }

      if (!response.ok) {
        let errorMessage = "An unknown error occurred";
        if (data && typeof data === "object") {
          Object.keys(data).forEach((propertyName) => {
            if (Array.isArray(data[propertyName])) {
              errorMessage = data[propertyName][0];
              toast.error(errorMessage);
            }
          });
        }
        throw new Error(errorMessage);
      }

      return data;
    } catch (error) {
      console.log("Error in SignIn:", error);
      throw error;
    }
  };

  const signInUserMutation = useMutation({
    mutationFn: userSignIn,
    mutationKey: ["SignIn User"],
    onSuccess(data) {
      login(data);
    },
    onError(error, variables) {
      toast.error(error.message || "Sign-in failed");
      console.log("ERROR:", error, variables);
    },
  });

  async function handleSignIn(
    values: UserData,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) {
    try {
      await signInUserMutation.mutateAsync(values);
    } catch (error) {
      console.log("SignIn error", error);
    } finally {
      setSubmitting(false);
    }
  }

  return {
    initialValues: {
      email: "",
      password: "",
    },
    handleSignIn,
  };
}
