import { useState, useEffect } from "react";
import { SymbolIcon, ListBulletIcon, CardStackIcon, ExternalLinkIcon } from "@radix-ui/react-icons";

import { Input } from "../common/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/ui/table";
import Rechart from "../common/Rechart";
import CompleteActivation from "../common/CompleteActivation";
import { CardTitle, TranferTitle, Wrapper } from "../Styles";
import {
  TitleWrapper,
  Title,
  TitleDescription,
  TransferContainer,
  TableWrapper,
  TableHeading,
  TableContainer,
  DashboardCardContainer,
  DashboardCardTitleContainer,
  DashboardCardMain,
  DashboardCardDescription,
} from "../Styles";

import { ComingSoon } from "../Styles";

import { useQuery } from "@tanstack/react-query";

import TransferDetails from "../common/TransferDetails";
import { getTransferHistory, getUser } from "src/services";
import Banner from "../common/Banner";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { Statuses, TransactionTypes } from "src/helpers/constants";
import { Business } from "./business";
import { Transfer } from "./transfer";
import { useToast } from "../common/ui/use-toast";
import { getBusinesses } from "../../services";

const cardData = [
  {
    title: "Total Lumi Balance",
    Icon: CardStackIcon,
    amount: "$34,000",
    percentage: "+20%",
  },
  {
    title: "Expends",
    Icon: SymbolIcon,
    amount: "$4,000",
    percentage: "+40%",
  },
  {
    title: "Completed Transactions",
    Icon: ListBulletIcon,
    amount: "50",
    percentage: "-80%",
  },
];

const invoices = [
  {
    invoice: "INV001",
    paymentStatus: "Paid",
    totalAmount: "$250.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV002",
    paymentStatus: "Pending",
    totalAmount: "$150.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV003",
    paymentStatus: "Unpaid",
    totalAmount: "$350.00",
    paymentMethod: "Bank Transfer",
  },
  {
    invoice: "INV004",
    paymentStatus: "Paid",
    totalAmount: "$450.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV005",
    paymentStatus: "Paid",
    totalAmount: "$550.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV006",
    paymentStatus: "Pending",
    totalAmount: "$200.00",
    paymentMethod: "Bank Transfer",
  },
  {
    invoice: "INV007",
    paymentStatus: "Unpaid",
    totalAmount: "$300.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV001",
    paymentStatus: "Paid",
    totalAmount: "$250.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV002",
    paymentStatus: "Pending",
    totalAmount: "$150.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV003",
    paymentStatus: "Unpaid",
    totalAmount: "$350.00",
    paymentMethod: "Bank Transfer",
  },
  {
    invoice: "INV004",
    paymentStatus: "Paid",
    totalAmount: "$450.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV005",
    paymentStatus: "Paid",
    totalAmount: "$550.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV001",
    paymentStatus: "Paid",
    totalAmount: "$250.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV002",
    paymentStatus: "Pending",
    totalAmount: "$150.00",
    paymentMethod: "PayPal",
  },
  {
    invoice: "INV003",
    paymentStatus: "Unpaid",
    totalAmount: "$350.00",
    paymentMethod: "Bank Transfer",
  },
  {
    invoice: "INV004",
    paymentStatus: "Paid",
    totalAmount: "$450.00",
    paymentMethod: "Credit Card",
  },
  {
    invoice: "INV005",
    paymentStatus: "Paid",
    totalAmount: "$550.00",
    paymentMethod: "PayPal",
  },
];

const History = () => {
  const [openActivation, setOpenActivation] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [page, setPage] = useState(1);
  const [openTransferDetail, setOpenTransferDetail] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState(null);

  const { toast } = useToast();

  const { data: userData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
    enabled: !!JSON.stringify(localStorage.getItem("userData")),
  });

  const handleGetBusinessData = async (userId: string) => {
    try {
      return await getBusinesses(userId);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load business.",
      });
    }
  };

  const {
    data: businessData,
    refetch: refetchBusinessData,
    isLoading: businessesIsLoading,
    isError: errorLoadingIsBusiness,
    isSuccess: businessDataSuccess,
  } = useQuery({
    queryKey: ["businessDetails", userData?.id],
    queryFn: () => handleGetBusinessData(userData?.id),
    enabled: !!userData?.id,
  });

  //fd62516f-0fb1-4eda-95bc-e336a61aae5b
  const isPendingActivation = !(
    userData?.user_images?.profile_picture && userData?.user_images?.user_id_image
  );

  useEffect(() => {
    if (userData?.id) {
      refetchBusinessData();
    }
  }, [page, refetchBusinessData, userData, filters]);

  function handleActionClose() {
    setOpenActivation(false);
  }

  // const handleNextPage = () => {
  //   setPage((prevPage) => prevPage + 1);
  // };

  // const handlePrevPage = () => {
  //   setPage((prevPage) => Math.max(prevPage - 1, 1));
  // };

  // const handleStatusSelect = (event: any) => {
  //   setPage(1);
  //   event?.value === "all"
  //     ? setFilters((prevFilters: any) => ({ ...prevFilters, status: null }))
  //     : setFilters((prevFilters: any) => ({ ...prevFilters, status: event?.value }));
  // };

  // const handleTransactionTypeSelect = (event: any) => {
  //   setPage(1);
  //   event?.value === "all"
  //     ? setFilters((prevFilters: any) => ({ ...prevFilters, transaction_type: null }))
  //     : setFilters((prevFilters: any) => ({ ...prevFilters, transaction_type: event?.value }));
  // };

  // function handleRowClick(props: any) {
  //   setOpenTransferDetail(true);
  //   setSelectedRowItem(props);
  // }

  return (
    <Wrapper>
      {isPendingActivation && <Banner />}
      <div className="flex flex-col w-full space-y-8">
        <Transfer />
        <Business businessData={businessData} businessesIsLoading={businessesIsLoading} />
      </div>
      <CompleteActivation
        open={openActivation}
        description="Complete activation to enable transfer initiation."
        handleClose={handleActionClose}
      />
    </Wrapper>
  );
};

// const History = () => {
//   const [openActivation, setOpenActivation] = useState(false);

//   function handleActionClose() {
//     setOpenActivation(false);
//   }

//   return (
//     <ComingSoon>Comming soon...</ComingSoon>
//     // <Wrapper>
//     //   <TitleWrapper>
//     //     <Title>Your History</Title>
//     //     <TitleDescription>Navigate through your money trial.</TitleDescription>
//     //   </TitleWrapper>

//     //   <TransferContainer>
//     //     {cardData?.map(({ Icon, title, amount, percentage }) => (
//     //       <DashboardCardContainer>
//     //         <DashboardCardTitleContainer>
//     //           <TranferTitle>{title}</TranferTitle>
//     //           <Icon width={15} height={15} />
//     //         </DashboardCardTitleContainer>
//     //         <DashboardCardMain>{amount}</DashboardCardMain>
//     //         <DashboardCardDescription>{percentage} from last month</DashboardCardDescription>
//     //       </DashboardCardContainer>
//     //     ))}
//     //   </TransferContainer>

//     //   <TableContainer noMargin="noMargin">
//     //     <div>
//     //       <CardTitle style={{ margin: '0 1rem 1rem ' }}>Overview</CardTitle>
//     //     </div>

//     //     <Rechart />
//     //   </TableContainer>

//     //   <TableContainer noMargin="noMargin">
//     //     <TableHeading>
//     //       <div>
//     //         <CardTitle>All transaction history</CardTitle>
//     //         <TitleDescription fontSize={0.3}>A list of all your tranfer history.</TitleDescription>
//     //       </div>

//     //       <div className="grid w-full max-w-sm items-center gap-1.5 m-1rem mt-5">
//     //         <Input type="text" id="amount" placeholder="Filter by Invoice number" />
//     //       </div>
//     //     </TableHeading>

//     //     <TableWrapper height="60vh">
//     //       <Table>
//     //         <TableHeader>
//     //           <TableRow>
//     //             <TableHead className="w-[100px]">Invoice</TableHead>
//     //             <TableHead>Status</TableHead>
//     //             <TableHead>Method</TableHead>
//     //             <TableHead className="text-right">Amount</TableHead>
//     //           </TableRow>
//     //         </TableHeader>
//     //         <TableBody>
//     //           {invoices.map((invoice) => (
//     //             <TableRow key={invoice.invoice}>
//     //               <TableCell className="font-medium">{invoice.invoice}</TableCell>
//     //               <TableCell>{invoice.paymentStatus}</TableCell>
//     //               <TableCell>{invoice.paymentMethod}</TableCell>
//     //               <TableCell className="text-right">{invoice.totalAmount}</TableCell>
//     //             </TableRow>
//     //           ))}
//     //         </TableBody>
//     //         <TableFooter>
//     //           <TableRow>
//     //             <TableCell colSpan={3}>Total</TableCell>
//     //             <TableCell className="text-right">$2,500.00</TableCell>
//     //           </TableRow>
//     //         </TableFooter>
//     //       </Table>
//     //     </TableWrapper>
//     //   </TableContainer>

//     //   <CompleteActivation
//     //     open={openActivation}
//     //     description="Complete activation to enable transfer initiation."
//     //     handleClose={handleActionClose}
//     //   />
//     // </Wrapper>
//   );
// };

export default History;
