import { Outlet } from "react-router-dom";
import SideNavigation from "./Layout";

const NavLayout = () => {
  return (
    <>
      <SideNavigation />
      <Outlet />
    </>
  );
};

export default NavLayout;
