import { useState, useEffect } from "react";
import { SymbolIcon, ExternalLinkIcon, ListBulletIcon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";

import InitiateTransfer from "./common/InitiateTransfer";
import TransferDetails from "./common/TransferDetails";
import { getTransferHistory, getUser } from "src/services";
import Banner from "./common/Banner";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./common/ui/table";
import CompleteActivation from "./common/CompleteActivation";
import BulkTransfer from "./common/BulkTransfer";
import { CardTitle, TranferTitle, Wrapper } from "./Styles";
import { Statuses, TransactionTypes } from "src/helpers/constants";
import {
  TransferCardContainer,
  TransferIcon,
  TransferDescription,
  TitleWrapper,
  Title,
  TitleDescription,
  TransferContainer,
  TableWrapper,
  TableHeading,
  TableContainer,
} from "./Styles";

const Transfer = () => {
  const [openActivation, setOpenActivation] = useState(false);
  const [openInitiateTransfer, setOpenInitiateTransfer] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [page, setPage] = useState(1);
  const [openTransferDetail, setOpenTransferDetail] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [openBulkTransfer, setOpenBulkTransfer] = useState(false);

  const { data: userData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
    enabled: !!JSON.stringify(localStorage.getItem("userData")),
  });

  const {
    data: transferHistory,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["transferHistory"],
    queryFn: () => getTransferHistory(userData?.id, page, filters),
    enabled: !!userData?.id,
  });

  const isPendingActivation = !(
    userData?.user_images?.profile_picture && userData?.user_images?.user_id_image
  );

  useEffect(() => {
    if (userData?.id) {
      refetch();
    }
  }, [page, refetch, userData, filters]);

  function handleActionClose() {
    setOpenActivation(false);
  }

  function handleCardClick(handleClick: any) {
    if (handleClick) {
      handleClick();
    } else {
      setOpenActivation(true);
    }
  }

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleStatusSelect = (event: any) => {
    setPage(1);
    event?.value === "all"
      ? setFilters((prevFilters: any) => ({ ...prevFilters, status: null }))
      : setFilters((prevFilters: any) => ({ ...prevFilters, status: event?.value }));
  };

  const handleTransactionTypeSelect = (event: any) => {
    setPage(1);
    event?.value === "all"
      ? setFilters((prevFilters: any) => ({ ...prevFilters, transaction_type: null }))
      : setFilters((prevFilters: any) => ({ ...prevFilters, transaction_type: event?.value }));
  };

  function handleRowClick(props: any) {
    setOpenTransferDetail(true);
    setSelectedRowItem(props);
  }

  const cardData = [
    {
      title: "Initiate Transfer",
      Icon: SymbolIcon,
      description: "Transfer money securely and quickly with Hanypay Transfer.",
      handleClick: () => setOpenInitiateTransfer(true),
    },
    {
      title: "Bulk Transfer",
      Icon: ExternalLinkIcon,
      description: "Send money in bulk to multiple recipients at once.",
      handleClick: () => setOpenBulkTransfer(true),
    },
    {
      title: "Pay Bills",
      Icon: ListBulletIcon,
      description: "Easily pay your bills online with just a few clicks.",
    },
  ];

  return (
    <Wrapper>
      {isPendingActivation && <Banner />}

      <TitleWrapper>
        <Title>Your Transfers</Title>
        <TitleDescription>
          Send money with ease using Lumi Transfer. Simple and secure transactions.
        </TitleDescription>
      </TitleWrapper>

      <TransferContainer>
        {cardData?.map(({ Icon, title, description, handleClick }) => (
          <TransferCardContainer key={title} onClick={() => handleCardClick(handleClick)}>
            <TransferIcon>
              <Icon width={25} height={25} />
            </TransferIcon>
            <TranferTitle>{title}</TranferTitle>
            <TransferDescription>{description}</TransferDescription>
          </TransferCardContainer>
        ))}
      </TransferContainer>

      <TableContainer>
        <TableHeading>
          <div>
            <CardTitle>Transfer history</CardTitle>
            <TitleDescription>A list of all your transfer history.</TitleDescription>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mt-5 mr-3">
              <Select
                onChange={handleStatusSelect}
                options={Statuses}
                isClearable={true}
                placeholder="Filter by status"
                isDisabled={isFetching}
              />
            </div>

            <div className="grid w-[250px] max-w-sm items-center gap-1.5 m-1rem mt-5">
              <Select
                onChange={handleTransactionTypeSelect}
                options={TransactionTypes}
                isClearable={true}
                placeholder="Transaction types"
                isDisabled={isFetching}
              />
            </div>
          </div>
        </TableHeading>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Type</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Currency</TableHead>
                <TableHead>Wallet number</TableHead>
                <TableHead className="text-right">Channel</TableHead>
                <TableHead>Mode</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Charges</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : isError ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Error fetching data
                  </TableCell>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    <button onClick={() => refetch()}>Refetch</button>
                  </TableCell>
                </TableRow>
              ) : transferHistory?.results?.length === 0 ? (
                <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                  Ooops, there are no items at the moment.
                </TableCell>
              ) : (
                transferHistory?.results?.map((props: any) => {
                  const {
                    transaction_type,
                    amount,
                    currency_code,
                    channel,
                    status,
                    wallet_number,
                    charges,
                    debit_or_credit,
                    reference,
                    description,
                    sender_wallet_number,
                    receiver_wallet_number,
                  } = props;
                  return (
                    <TableRow key={uuidv4()} onClick={() => handleRowClick(props)}>
                      <TableCell className="font-medium">{transaction_type}</TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{currency_code}</TableCell>
                      <TableCell>
                        {debit_or_credit === "Debit"
                          ? receiver_wallet_number
                          : sender_wallet_number}
                      </TableCell>
                      <TableCell className="text-right">{channel}</TableCell>
                      <TableCell>{debit_or_credit}</TableCell>
                      <TableCell>{status}</TableCell>
                      <TableCell>{charges}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={handlePrevPage}
            disabled={page === 1 || !transferHistory?.previous}
            className={`btn ${
              page === 1 || !transferHistory?.previous ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {"<<  Previous"}
          </button>
          <span className="mx-4">Page {page}</span>
          <button
            onClick={handleNextPage}
            disabled={!transferHistory?.next}
            className={`btn ${!transferHistory?.next ? "cursor-not-allowed opacity-50" : ""}`}
          >
            {"Next >>"}
          </button>
        </div>
      </TableContainer>
      <CompleteActivation
        open={openActivation}
        description="Complete activation to enable transfer initiation."
        handleClose={handleActionClose}
      />
      <InitiateTransfer
        open={openInitiateTransfer}
        handleClose={() => setOpenInitiateTransfer(false)}
      />
      <TransferDetails
        open={openTransferDetail}
        handleClose={() => setOpenTransferDetail(false)}
        details={selectedRowItem}
      />
      <BulkTransfer open={openBulkTransfer} handleClose={() => setOpenBulkTransfer(false)} />
    </Wrapper>
  );
};

export default Transfer;
