interface FormValues {
  firstname: any;
  lastname: any;
  address: any;
  city: any;
  user_name: any;
  phone: any;
  password: any;
  mobileCode: any;
  amount: any;
  receiver_wallet_number: any;
  name: any;
  email: any;
  id_director_one: any;
  id_director_two: any;
  transaction_volume: any;
  size: any;
}

interface Errors {
  firstname?: boolean;
  lastname?: boolean;
  address?: boolean;
  user_name?: boolean;
  phone?: boolean;
  password?: boolean;
  firstNameErrorMessage?: string;
  lastNameErrorMessage?: string;
  addressErrorMessage?: string;
  city?: boolean;
  cityErrorMessage?: string;
  userNameErrorMessage?: string;
  phoneErrorMessage?: string;
  passwordMessage?: string;
  amount?: boolean;
  amountErrorMessage?: string;
  receiver_wallet_number?: boolean;
  receiverWalletNumberErrorMessage?: string;
  name?: boolean;
  nameErrorMessage?: string;
  email?: boolean;
  emailErrorMessage?: string;
  id_director_one?: boolean;
  idDirectorOneErrorMessage?: string;
  id_director_two?: boolean;
  idDirectorTwoErrorMessage?: string;
  transaction_volume?: boolean;
  transactionVolumeErrorMessage?: string;
  sourceOfRawMaterialsErrorMessage?: string;
  size?: boolean;
  sizeErrorMessage?: string;
}

export default function validate(values: FormValues): Errors {
  const {
    firstname,
    lastname,
    address,
    user_name,
    phone,
    password,
    amount,
    receiver_wallet_number,
    name,
    email,
    id_director_one,
    id_director_two,
    transaction_volume,
    size,
  } = values;
  let errors: Errors = {};

  const checkAmount = validateAmount(amount);
  if (checkAmount) {
    errors.amount = true;
    errors.amountErrorMessage = checkAmount;
  }

  const checkCompanySize = validateCompanySize(size);
  if (checkCompanySize) {
    errors.size = true;
    errors.sizeErrorMessage = checkCompanySize;
  }

  const checkReceiverWalletNumber = validateReceiverWalletNumber(receiver_wallet_number);
  if (checkReceiverWalletNumber) {
    errors.receiver_wallet_number = true;
    errors.receiverWalletNumberErrorMessage = checkReceiverWalletNumber;
  }

  const checkIdDirectorOne = validateName(id_director_one, "First director name");
  if (checkIdDirectorOne) {
    errors.id_director_one = true;
    errors.idDirectorOneErrorMessage = checkIdDirectorOne;
  }

  const checkIdDirectorTwo = validateName(id_director_two, "Second director name");
  if (checkIdDirectorTwo) {
    errors.id_director_two = true;
    errors.idDirectorTwoErrorMessage = checkIdDirectorTwo;
  }

  const checkTransactionVolume = validateVolume(transaction_volume);
  if (checkTransactionVolume) {
    errors.transaction_volume = true;
    errors.transactionVolumeErrorMessage = checkTransactionVolume;
  }

  const checkName = validateName(name, "Business name");
  if (checkName) {
    errors.name = true;
    errors.nameErrorMessage = checkName;
  }

  const checkEmail = validateEmail(email);
  if (checkEmail) {
    errors.email = true;
    errors.emailErrorMessage = checkEmail;
  }

  const checkFirstName = validateName(firstname, "First Name");
  if (checkFirstName) {
    errors.firstname = true;
    errors.firstNameErrorMessage = checkFirstName;
  }

  const checkLastName = validateName(lastname, "Last Name");
  if (checkLastName) {
    errors.lastname = true;
    errors.lastNameErrorMessage = checkLastName;
  }

  const checkAddress = validateName(address, "Address");
  if (checkAddress) {
    errors.address = true;
    errors.addressErrorMessage = checkAddress;
  }

  const checkCity = validateName(address, "Address");
  if (checkCity) {
    errors.address = true;
    errors.cityErrorMessage = checkCity;
  }

  const checkUserName = validateName(user_name, "User Name");
  if (checkUserName) {
    errors.user_name = true;
    errors.userNameErrorMessage = checkUserName;
  }

  const checkphone = validatephone(phone);
  if (checkphone) {
    errors.phone = true;
    errors.phoneErrorMessage = checkphone;
  }

  const checkPassword = password && validatePassword(password);
  if (checkPassword) {
    errors.password = true;
    errors.passwordMessage = checkPassword;
  }

  return errors;
}

function validatePassword(passwordText: string): string | null {
  if (passwordText?.length !== 4) {
    return "Must be 4 numbers";
  }
  return null;
}

function validatephone(phoneNo: string): string | null {
  if (phoneNo === "") {
    return "This field cannot be empty";
  } else if (phoneNo && phoneNo[0] !== "0") {
    return "This field must be prefixed with a 0";
  } else if (phoneNo && !/^\d+$/.test(phoneNo)) {
    return "Phone numbers can only be numbers.";
  } else return null;
}

function validateName(nameValue: any, nameType: string): string | null {
  const fieldName = nameType ? nameType : "unknown field";
  if (nameValue?.trim() === "") {
    return `${fieldName} cannot be empty`;
  } else if (nameValue?.trim()?.length < 2) {
    return `${fieldName} cannot be less than 2 letters`;
  } else return null;
}

function validateAmount(amount: number): string | null {
  if (amount <= 0) {
    return "Amount cannot empty";
  } else {
    return null;
  }
}

function validateReceiverWalletNumber(walletNumber: string): string | null {
  if (walletNumber?.length < 1) {
    return "Receiver wallet number cannot be empty";
  }
  if (walletNumber?.length < 16) {
    return "Wallet number cannot be less than 16 digits";
  }
  if (walletNumber?.length > 16) {
    return "Wallet number cannot be more than 16 digits";
  } else {
    return null;
  }
}

function validateEmail(email: string): string | null {
  if (email?.trim() === "") {
    return "Email cannot be empty";
  } else if (email && !/^\S+@\S+\.\S+$/.test(email)) {
    return "Invalid email format";
  }
  return null;
}

function validateCompanySize(size: number): string | null {
  if (size < 1) {
    return "Company size cannot be empty";
  }
  return null;
}

function validateVolume(size: number): string | null {
  if (size < 1) {
    return "Monthly transaction volume cannot be empty";
  }
  return null;
}
