import { useState, useEffect } from "react";
import logo from "../../assests/images/logo-dark-h.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  HomeIcon,
  MarketplaceIcon,
  HistoryIcon,
  WalletIcon,
  AccountIcon,
  BusinessIcon,
} from "../../assests/svgs/NavIcons";

import { getUser } from "../../services/index";
import { NAV_ITEMS } from "../../helpers/constants";
import { theme } from "../../theme";
import { SideNavContainer, NavHeader, NavList, NavItem, IconWrapper } from "./Styles";

const SideNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState<NAV_ITEMS>();

  const links = [
    {
      Icon: HomeIcon,
      title: NAV_ITEMS.TRANSFER,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.TRANSFER);
        navigate("/home/transfer");
      },
    },
    {
      Icon: WalletIcon,
      title: NAV_ITEMS.WALLETS,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.WALLETS);
        navigate("/home/wallets");
      },
    },
    {
      Icon: HistoryIcon,
      title: NAV_ITEMS.HISTORY,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.HISTORY);
        navigate("/home/history");
      },
    },
    {
      Icon: MarketplaceIcon,
      title: NAV_ITEMS.MARKETPLACE,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.MARKETPLACE);
        navigate("/home/marketplace");
      },
    },
    {
      Icon: BusinessIcon,
      title: NAV_ITEMS.BUSINESS,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.BUSINESS);
        navigate("/home/business");
      },
    },
    {
      Icon: MarketplaceIcon,
      title: NAV_ITEMS.TRADING,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.TRADING);
        navigate("/home/trading");
      },
    },
    {
      Icon: AccountIcon,
      title: NAV_ITEMS.ACCOUNT,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.ACCOUNT);
        navigate("/home/account");
      },
    },
  ];

  useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  useEffect(() => {
    const pathMap = {
      "/transfer": NAV_ITEMS.TRANSFER,
      "/wallets": NAV_ITEMS.WALLETS,
      "/history": NAV_ITEMS.HISTORY,
      "/marketplace": NAV_ITEMS.MARKETPLACE,
      "/business": NAV_ITEMS.BUSINESS,
      "/trading": NAV_ITEMS.TRADING,
      "/account": NAV_ITEMS.ACCOUNT,
    };

    const selectedItem = Object.entries(pathMap).find(([path]) =>
      location?.pathname.includes(path),
    );

    setSelectedItem(selectedItem ? selectedItem[1] : NAV_ITEMS.TRANSFER);
  }, [location.pathname]);

  return (
    <SideNavContainer>
      <NavHeader onClick={() => navigate("/home/transfer")}>
        <img src={logo} alt="logo" width={150} height={150} />
      </NavHeader>
      <NavList>
        {links?.map(({ Icon, title, handleClick }) => (
          <NavItem key={title} onClick={handleClick} selected={title === selectedItem}>
            <IconWrapper>
              <Icon
                fill={title === selectedItem ? theme.colors.secondary : theme.colors.text}
                selected={Boolean(title === selectedItem)}
              />
            </IconWrapper>{" "}
            {title}
          </NavItem>
        ))}
      </NavList>
    </SideNavContainer>
  );
};

export default SideNavigation;
