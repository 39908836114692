export const formatAmount = (amount: string): string => {
  amount = amount.toString();
  let parts = amount.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export function truncateFileName(filename: string) {
  if (filename.length > 15) {
    let start = filename.substring(0, 15);
    let end = filename.substring(filename.length - 5, filename.length);
    return start + "..." + end;
  }
}

export function capitalizeFirstLetter(string: string) {
  if (string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getButtonText = (order: {
  trade_role: string;
  confirmed_by_buyer: boolean;
  confirmed_by_seller: boolean;
  status: string;
  is_initiator: boolean;
  is_accepted: boolean;
}) => {
  if (order?.status === "Pending" && !order?.is_accepted && !order?.is_initiator) {
    return "Accept Order";
  } else if (order?.status === "Pending" && !order?.is_accepted && order?.is_initiator) {
    return "Awaiting Confirmation";
  }

  if (order?.trade_role === "BUYER") {
    if (!order?.confirmed_by_buyer) {
      return "Transferred, Notify Seller";
    }
    if (!order?.confirmed_by_seller) {
      return "Awaiting Seller Confirmation";
    }
  }
  if (order?.trade_role === "SELLER" && !order?.confirmed_by_buyer) {
    return "Awaiting Buyer Confirmation";
  } else if (order?.trade_role === "SELLER" && order?.confirmed_by_buyer) {
    return "Received, Release AKL";
  }
  return "Confirm";
};
