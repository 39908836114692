import { IconProps } from "./types";

export function Close({ fill }: IconProps) {
  return (
    <>
      <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Action / clear</title>
        <desc>Created with Sketch.</desc>
        <g id="User-management" stroke="none" strokeWidth="1" fill={fill} fillRule="nonzero">
          <g id="Add-user-via-invite" transform="translate(-1009.000000, -212.000000)">
            <g id="Modal" transform="translate(380.000000, 175.000000)">
              <g id="Content" transform="translate(32.000000, 32.000000)">
                <g id="Action-/-clear" transform="translate(592.000000, 0.000000)">
                  <g>
                    <polygon id="Bounds" points="0 0 24 0 24 24 0 24"></polygon>
                    <polygon
                      id="Icon"
                      fillOpacity="0.4"
                      fill="#000000"
                      points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
