import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "../common/ui/select";
import { ArrowRightIcon, Loader2 } from "lucide-react";
import Lottie from "react-lottie";
import { Label } from "./ui/label";
import { currencies } from "src/helpers/countriesWithCurrencies";
import {
  CardsWrapper,
  Card,
  CardTitle,
  CurrencySelectWrapper,
  IconWrapper,
  RateContainer,
  RateLabel,
  RateContent,
  ExchangeCard,
} from "../Styles";
import { Input } from "./ui/input";
import emptyData from "../../assests/animations/empty.json";
import { useExchangeMutationApi } from "src/hooks/useTrading";
import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import ReactSelect from "react-select";

const animationDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: emptyData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Conversion = () => {
  const convert = useExchangeMutationApi();
  const [baseCurrency, setBaseCurrency] = useState("");
  const [targetCurrency, setTargetCurrency] = useState("");
  const [amount, setAmount] = useState("");

  function handleExchange() {
    convert.mutate({
      amount: amount,
      base_currency_code: baseCurrency,
      target_currency_code: targetCurrency,
    });
  }

  const disableConvert = !baseCurrency || !targetCurrency || !amount;

  return (
    <CardsWrapper>
      <ExchangeCard width={50}>
        <CardTitle>Quick Conversion</CardTitle>

        <CurrencySelectWrapper>
          <div className="w-1/2">
            <Label>From</Label>
            <ReactSelect
              options={currencies}
              formatOptionLabel={(option) => `${option.value} - ${option.label}`}
              isClearable={true}
              isSearchable={true}
              onChange={(option) => {
                setBaseCurrency(option?.value || "");
              }}
            />
          </div>

          <IconWrapper>
            <ArrowRightIcon />
          </IconWrapper>

          <div className="w-1/2">
            <Label>To</Label>
            <ReactSelect
              options={currencies}
              formatOptionLabel={(option) => `${option.value} - ${option.label}`}
              isClearable
              isSearchable
              onChange={(option) => {
                setTargetCurrency(option?.value || "");
              }}
            />
          </div>
        </CurrencySelectWrapper>

        <div className="grid w-full max-w-sm items-center gap-1.5 m-1rem">
          <Label htmlFor="amount">Amount</Label>
          <Input
            type="text"
            id="amount"
            placeholder="Amount"
            onChange={(event) => setAmount(event.target.value)}
            defaultValue={amount || ""}
          />
        </div>
        <Button
          onClick={handleExchange}
          style={{}}
          disabled={disableConvert}
          className="flex w-full items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-4"
        >
          {convert.isPending ? <Loader2 /> : "Convert"}
        </Button>

        <RateContainer>
          <div>
            <RateLabel>Rate</RateLabel>
            <RateContent>
              <p>{convert?.data?.rate || "__"}</p>
            </RateContent>
          </div>

          <div>
            <RateLabel>Expected amount</RateLabel>
            <RateContent>{convert?.data?.converted_amount || "__"}</RateContent>
          </div>
        </RateContainer>
      </ExchangeCard>
    </CardsWrapper>
  );
};

export default Conversion;
