import { useState } from "react";
import { Wrapper } from "./Styles";
import { AccountNav, AccountNavItem } from "./common/Styles";
import Business from "./Business";
// import BusinessSelect from "./common/BusinessSelect";

const BUSINESSNAVITEMS = [{ name: "Business" }];

const BusinessComponent = () => {
  const [selectedItem, setSelectedItem] = useState<string>(BUSINESSNAVITEMS[0].name);

  // const isKYCVerification = selectedItem === BUSINESSNAVITEMS[1].name;

  return (
    <Wrapper>
      <AccountNav>
        {BUSINESSNAVITEMS?.map(({ name }) => (
          <AccountNavItem
            key={name}
            selectedItem={selectedItem === name ? name : ""}
            onClick={() => setSelectedItem(name)}
          >
            {name}
          </AccountNavItem>
        ))}
      </AccountNav>
      <Business />
    </Wrapper>
  );
};

export default BusinessComponent;
