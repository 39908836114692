import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
function ErrorPage() {
  const { user } = useAuth();
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-xl lg:w-full">
          <div className="items-center flex flex-col">
            <h1 className="py-4 text-8xl font-black text-gray-600 ">404</h1>
            <h1 className="py-4 text-3xl text-center lg:text-4xl font-extrabold text-gray-800 ">
              Sorry! We can't find the page you're looking for.
            </h1>
            <p className="py-4 text-base text-gray-800 text-center">
              Please check whether you mistyped the link,or visit our hompage to get where you need
              to go.
            </p>
            <p className="py-2 text-base text-gray-800 "></p>
            <div className="mt-6">
              <Link
                to={user ? "/home/transfer" : "/"}
                type="submit"
                className="flex w-full justify-center py-3 rounded-md bg-red-500 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Go to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
