import * as Dialog from "@radix-ui/react-dialog";

import styled from "styled-components";
import { Button } from "./ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";

const TransferDetails = ({
  open,
  handleClose,
  details,
}: {
  open: boolean;
  handleClose: () => void;
  details: any;
}) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <DialogContent className="DialogContentSmall">
          <Dialog.Title className="DialogTitle">Transfer Details</Dialog.Title>

          <div style={{ display: "flex", flexDirection: "column", marginTop: 25 }}>
            <Row>
              <Item>WalletID</Item>
              <Item>
                {details?.debit_or_credit === "Debit"
                  ? details?.receiver_wallet_number
                  : details?.sender_wallet_number}
              </Item>
            </Row>

            <Row>
              <Item>Date</Item>
              <Item>{new Date(details?.created_at).toLocaleString()}</Item>
            </Row>

            <Row>
              <Item>RefID</Item>
              <Item>{details?.reference}</Item>
            </Row>

            <Row>
              <Item>Amount</Item>
              <Item>{details?.amount}</Item>
            </Row>

            <Row>
              <Item>Currency</Item>
              <Item>{details?.currency_code}</Item>
            </Row>

            <Row>
              <Item>Charges</Item>
              <Item>{details?.charges}</Item>
            </Row>

            <Row>
              <Item>Channel</Item>
              <Item>{details?.channel}</Item>
            </Row>

            <Row>
              <Item>Transaction Type</Item>
              <Item>{details?.transaction_type}</Item>
            </Row>

            <Row>
              <Item>Mode</Item>
              <Item>{details?.debit_or_credit}</Item>
            </Row>

            <Row>
              <Item>Status</Item>
              <Item>{details?.status}</Item>
            </Row>

            <div className="flex flex-col space-y-1 w-full pt-[2rem]">
              <p className="font-bold italic">Description:</p>
              <p className="text-sm">{details?.description}</p>
            </div>
          </div>
          <Dialog.Close asChild>
            <CloseIconButton className="IconButton" aria-label="Close" onClick={handleClose}>
              <Cross2Icon width="20" height="20" />
            </CloseIconButton>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <StyledButton
                onClick={() => window.print()}
                type="submit"
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Print
              </StyledButton>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <CloseIconButton onClick={handleClose} className="IconButton" aria-label="Close">
              <Cross2Icon width="20" height="20" />
            </CloseIconButton>
          </Dialog.Close>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const CloseIconButton = styled.button`
  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const DialogContent = styled(Dialog.Content)`
  @media print {
    width: 100vw;
    heigt: 100vh;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 0.5rem;
`;

const Item = styled.div`
  width: 50%;
`;

export default TransferDetails;
