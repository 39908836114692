import { useState, useEffect } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { useToast } from "./ui/use-toast";
import ConfirmTransfer from "./ConfirmTransfer";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { FormProvider, useForm } from "react-hook-form";
import useReactForm from "../../hooks/useForm";
import { FormItem, FormMessage } from "./ui/form";
import "../custom-styles.css";
import validate from "../../helpers/validate";
import { formatAmount } from "../../helpers/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { getUser, walletTransfer, getUserId, getBusinessWallet } from "../../services/index";
import { BalanceContainer, BalanceMain } from "./Styles";

const InitiateTransfer = ({
  open,
  handleClose,
  businesses,
  isBusinessTransfer,
  isLoadingBusiness,
}: {
  open: boolean;
  handleClose: () => void;
  businesses?: any;
  isBusinessTransfer?: boolean;
  isLoadingBusiness?: boolean;
}) => {
  const methods = useForm();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { values, errors, setValues, handleChange, reset } = useReactForm(validate);

  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState<any>({
    success: null,
    error: false,
    errorMessage: null,
  });

  const handleUserId = async (walletNumber: number) => {
    try {
      return await getUserId(walletNumber);
    } catch (error: any) {
      setConfirmationDetails({
        error: true,
        errorMessage:
          error?.response?.data?.message ?? "An error occured whilst fetching receiver details",
      });
      toast({
        variant: "destructive",
        title: "Error",
        description: error?.response?.data?.message,
      });
    }
  };

  const {
    data: businessWalletData,
    isLoading: isLoadingBusinessWalletData,
    refetch,
  } = useQuery({
    queryKey: ["businessWallet"],
    queryFn: () => getBusinessWallet(selectedBusiness),
    enabled: !!selectedBusiness,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness?.id]);

  const { isLoading: walletIsLoading, data: userData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
    enabled: !!JSON.stringify(localStorage.getItem("userData")),
  });

  const wallets = userData?.wallets;

  const walletOptions = isBusinessTransfer ? businessWalletData : wallets;

  const {
    data: userId,
    isFetching: userIdIsLoading,
    isError: userIdIsError,
    isSuccess: userIdSuccess,
  } = useQuery({
    queryKey: ["userId"],
    queryFn: () => handleUserId(values?.receiver_wallet_number),
    enabled: openConfirm,
  });

  useEffect(() => {
    if (userIdSuccess) setConfirmationDetails({ success: userId });
  }, [userIdSuccess, userIdIsError, toast, userId, openConfirm]);

  const transferMutation = useMutation({
    mutationKey: ["transfer"],
    mutationFn: (payload: any) => walletTransfer(payload),
    onSuccess: async () => {
      toast({
        description: "Wallet transfer was successful",
      });
      queryClient.invalidateQueries({ queryKey: ["transferHistory"] });
      queryClient.invalidateQueries({ queryKey: ["businessWallet"] });
      queryClient.refetchQueries({ queryKey: ["transferHistoryQueryKey"] });
      handleModalClose();
    },
    onError: (error: any) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: error?.response?.data.message ?? "Wallet transfer failed",
      });
    },
  });

  const handleWalletSelect = (event: any) => {
    setSelectedCurrency(event);
    setValues((prevValues: any) => ({
      ...prevValues,
      sender_wallet_number: event?.number,
    }));
  };

  const handleBusinessSelect = (event: any) => {
    setSelectedBusiness(event);
    setValues((prevValues: any) => ({
      ...prevValues,
      business_id: event?.id,
    }));
  };

  const handleTransfer = () => {
    transferMutation.mutate(values);
  };

  const handleModalClose = () => {
    handleClose();
    setOpenConfirm(false);
    reset();
    setSelectedCurrency(null);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setConfirmationDetails({});
  };

  const formatWalletNumber = (walletNumber: string | undefined | null): string => {
    if (!walletNumber) return "";
    return walletNumber
      .replace(/[^\d]/g, "")
      .slice(0, 16)
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Transfer Money</Dialog.Title>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormProvider {...methods}>
              {isBusinessTransfer && (
                <FormItem className="mt-5">
                  <Label>Select business*</Label>
                  <Select
                    disabled={isLoadingBusiness}
                    onValueChange={(event) => handleBusinessSelect(event)}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue
                        placeholder={isLoadingBusiness ? "loading business..." : "select business"}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {businesses?.map(({ id, name }: { id: string; name: string }) => (
                          <SelectItem key={id} value={id}>
                            {name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
              <FormItem className="mt-5">
                <Label>Select wallet to transfer*</Label>

                <Select
                  disabled={walletIsLoading || isLoadingBusinessWalletData}
                  onValueChange={(event) => handleWalletSelect(event)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue
                      placeholder={walletIsLoading ? "loading wallets..." : "select wallet"}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {walletOptions?.map((wallet: any) => (
                        <SelectItem key={wallet.currency} value={wallet}>
                          {wallet.currency_value}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>

              {selectedCurrency && (
                <BalanceContainer>
                  <p style={{ fontSize: "0.8rem", color: "#994D1C" }}>Current balance: </p>
                  <BalanceMain>
                    {selectedCurrency.currency} {formatAmount(selectedCurrency.balance)}
                  </BalanceMain>
                </BalanceContainer>
              )}

              <FormItem className="mt-5">
                <Label>Amount to transfer*</Label>
                <Input
                  name="amount"
                  placeholder="2000"
                  onChange={handleChange}
                  value={values?.amount || ""}
                  type="number"
                  min={1}
                />
                {errors?.amount && <FormMessage>{errors?.amountErrorMessage}</FormMessage>}
              </FormItem>

              <FormItem className="mt-5">
                <Label>Receiver's wallet number*</Label>
                <Input
                  type="text"
                  name="receiver_wallet_number"
                  onChange={handleChange}
                  value={formatWalletNumber(values?.receiver_wallet_number || "")}
                  maxLength={19}
                />
                {errors?.receiver_wallet_number && (
                  <FormMessage>{errors?.receiverWalletNumberErrorMessage}</FormMessage>
                )}
              </FormItem>

              <FormItem className="mt-5">
                <Label>Description</Label>
                <Input
                  name="description"
                  placeholder="description"
                  onChange={handleChange}
                  value={values?.description || ""}
                />
                {errors?.description && <FormMessage>{errors?.description}</FormMessage>}
              </FormItem>
            </FormProvider>
          </div>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}></div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={handleModalClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <Button
                type="submit"
                disabled={
                  !values?.sender_wallet_number ||
                  !values?.receiver_wallet_number ||
                  (values?.amount !== undefined &&
                    values?.amount !== null &&
                    parseFloat(values?.amount) < 0) ||
                  Boolean(Object.values(errors).length) ||
                  transferMutation.isPending
                }
                onClick={() => setOpenConfirm(true)}
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                {transferMutation.isPending ? "Loading..." : "Transfer"}
              </Button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button onClick={handleModalClose} className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
      <ConfirmTransfer
        open={openConfirm}
        handleClose={handleModalClose}
        handleConfirmClose={handleConfirmClose}
        handleConfirm={handleTransfer}
        values={values}
        selectedCurrency={selectedCurrency}
        transferMutation={transferMutation}
        confirmationDetails={confirmationDetails}
        isLoading={userIdIsLoading}
      />
    </Dialog.Root>
  );
};

export default InitiateTransfer;
