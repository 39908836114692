export const currencies = [
  {
    label: "LUMI",
    value: "AKL",
  },
  {
    label: "Afghanistan",
    value: "AFN",
  },
  {
    label: "Albania",
    value: "ALL",
  },
  {
    label: "Algeria",
    value: "DZD",
  },
  {
    label: "Angola",
    value: "AOA",
  },
  {
    label: "Argentina",
    value: "ARS",
  },
  {
    label: "Armenia",
    value: "AMD",
  },
  {
    label: "Aruba",
    value: "AWG",
  },
  {
    label: "Australia",
    value: "AUD",
  },
  {
    label: "Azerbaijan",
    value: "AZN",
  },
  {
    label: "Bahamas",
    value: "BSD",
  },
  {
    label: "Bahrain",
    value: "BHD",
  },
  {
    label: "Bangladesh",
    value: "BDT",
  },
  {
    label: "Barbados",
    value: "BBD",
  },
  {
    label: "Belarus",
    value: "BYR",
  },
  {
    label: "Belize",
    value: "BZD",
  },
  {
    label: "CFA franc BCEAO",
    value: "XOF",
  },
  {
    label: "Bermuda",
    value: "BMD",
  },
  {
    label: "Bhutan",
    value: "BTN",
  },
  {
    label: "Bolivia",
    value: "BOB",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BAM",
  },
  {
    label: "Botswana",
    value: "BWP",
  },
  {
    label: "Bouvet Island",
    value: "NOK",
  },
  {
    label: "Brazil",
    value: "BRL",
  },
  {
    label: "Brunei",
    value: "BND",
  },
  {
    label: "Bulgaria",
    value: "BGN",
  },
  {
    label: "Burundi",
    value: "BIF",
  },
  {
    label: "Cambodia",
    value: "KHR",
  },
  {
    label: "Canada",
    value: "CAD",
  },
  {
    label: "Cape Verde",
    value: "CVE",
  },
  {
    label: "Cayman Islands",
    value: "KYD",
  },
  {
    label: "Chile",
    value: "CLP",
  },
  {
    label: "China",
    value: "CNY",
  },
  {
    label: "Colombia",
    value: "COP",
  },
  {
    label: "Comoros",
    value: "KMF",
  },
  {
    label: "Cook Islands",
    value: "NZD",
  },
  {
    label: "Costa Rica",
    value: "CRC",
  },
  {
    label: "Cuba",
    value: "CUP",
  },
  {
    label: "Czech Republic",
    value: "CZK",
  },
  {
    label: "Denmark",
    value: "DKK",
  },
  {
    label: "Djibouti",
    value: "DJF",
  },
  {
    label: "Dominican Republic",
    value: "DOP",
  },
  {
    label: "Ecuador",
    value: "ECS",
  },
  {
    label: "European Union",
    value: "EUR",
  },
  {
    label: "Egypt",
    value: "EGP",
  },
  {
    label: "El Salvador",
    value: "SVC",
  },
  {
    label: "Eritrea",
    value: "ERN",
  },
  {
    label: "Eswatini",
    value: "SZL",
  },
  {
    label: "Ethiopia",
    value: "ETB",
  },
  {
    label: "Falkland Islands",
    value: "FKP",
  },
  {
    label: "Faroe Islands",
    value: "DKK",
  },
  {
    label: "Fiji Islands",
    value: "FJD",
  },
  {
    label: "Gambia",
    value: "GMD",
  },
  {
    label: "Georgia",
    value: "GEL",
  },
  {
    label: "Ghana",
    value: "GHS",
  },
  {
    label: "Gibraltar",
    value: "GIP",
  },
  {
    label: "Greenland",
    value: "DKK",
  },
  {
    label: "Dollar",
    value: "XCD",
  },
  {
    label: "Guatemala",
    value: "QTQ",
  },
  {
    label: "Guinea",
    value: "GNF",
  },
  {
    label: "Guinea-Bissau",
    value: "CFA",
  },
  {
    label: "Guyana",
    value: "GYD",
  },
  {
    label: "Haiti",
    value: "HTG",
  },
  {
    label: "Honduras",
    value: "HNL",
  },
  {
    label: "Hong Kong",
    value: "HKD",
  },
  {
    label: "Hungary",
    value: "HUF",
  },
  {
    label: "Iceland",
    value: "ISK",
  },
  {
    label: "India",
    value: "INR",
  },
  {
    label: "Indonesia",
    value: "IDR",
  },
  {
    label: "Iran",
    value: "IRR",
  },
  {
    label: "Iraq",
    value: "IQD",
  },
  {
    label: "Israel",
    value: "ILS",
  },
  {
    label: "Jamaica",
    value: "JMD",
  },
  {
    label: "Japan",
    value: "JPY",
  },
  {
    label: "Jordan",
    value: "JOD",
  },
  {
    label: "Kazakhstan",
    value: "KZT",
  },
  {
    label: "Kenya",
    value: "KES",
  },
  {
    label: "Kuwait",
    value: "KWD",
  },
  {
    label: "Kyrgyzstan",
    value: "KGS",
  },
  {
    label: "Laos",
    value: "LAK",
  },
  {
    label: "Lebanon",
    value: "LBP",
  },
  {
    label: "Lesotho",
    value: "LSL",
  },
  {
    label: "Liberia",
    value: "LRD",
  },
  {
    label: "Libya",
    value: "LYD",
  },
  {
    label: "Liechtenstein",
    value: "CHF",
  },
  {
    label: "Macau",
    value: "MOP",
  },
  {
    label: "North Macedonia",
    value: "MKD",
  },
  {
    label: "Madagascar",
    value: "MGF",
  },
  {
    label: "Malawi",
    value: "MWK",
  },
  {
    label: "Malaysia",
    value: "MYR",
  },
  {
    label: "Maldives",
    value: "MVR",
  },
  {
    label: "Mauritania",
    value: "MRO",
  },
  {
    label: "Mauritius",
    value: "MUR",
  },
  {
    label: "Mexico",
    value: "MXN",
  },
  {
    label: "Moldova",
    value: "MDL",
  },
  {
    label: "Mongolia",
    value: "MNT",
  },
  {
    label: "Morocco",
    value: "MAD",
  },
  {
    label: "Mozambique",
    value: "MZN",
  },
  {
    label: "Myanmar",
    value: "MMR",
  },
  {
    label: "Namibia",
    value: "NAD",
  },
  {
    label: "Nepal",
    value: "NPR",
  },
  {
    label: "Netherlands Antilles",
    value: "ANG",
  },
  {
    label: "New Zealand",
    value: "NZD",
  },
  {
    label: "Nicaragua",
    value: "NIO",
  },
  {
    label: "Nigeria",
    value: "NGN",
  },
  {
    label: "Niue",
    value: "NZD",
  },
  {
    label: "North Korea",
    value: "KPW",
  },

  {
    label: "Norway",
    value: "NOK",
  },
  {
    label: "Oman",
    value: "OMR",
  },
  {
    label: "Pakistan",
    value: "PKR",
  },
  {
    label: "Palestine",
    value: "ILS",
  },
  {
    label: "Panama",
    value: "PAB",
  },
  {
    label: "Papua New Guinea",
    value: "PGK",
  },
  {
    label: "Paraguay",
    value: "PYG",
  },
  {
    label: "Peru",
    value: "PEN",
  },
  {
    label: "Philippines",
    value: "PHP",
  },
  {
    label: "Pitcairn Islands",
    value: "NZD",
  },
  {
    label: "Poland",
    value: "PLN",
  },
  {
    label: "Qatar",
    value: "QAR",
  },
  {
    label: "Romania",
    value: "RON",
  },
  {
    label: "Russia",
    value: "RUB",
  },
  {
    label: "Rwanda",
    value: "RWF",
  },
  {
    label: "Saint Helena",
    value: "SHP",
  },
  {
    label: "Samoa",
    value: "WST",
  },
  {
    label: "Sao Tome and Principe",
    value: "STD",
  },
  {
    label: "Saudi Arabia",
    value: "SAR",
  },
  {
    label: "Serbia",
    value: "RSD",
  },
  {
    label: "Seychelles",
    value: "SCR",
  },
  {
    label: "Sierra Leone",
    value: "SLL",
  },
  {
    label: "Singapore",
    value: "SGD",
  },
  {
    label: "Solomon Islands",
    value: "SBD",
  },
  {
    label: "Somalia",
    value: "SOS",
  },
  {
    label: "South Africa",
    value: "ZAR",
  },
  {
    label: "South Korea",
    value: "KRW",
  },
  {
    label: "South Sudan",
    value: "SSP",
  },
  {
    label: "Sri Lanka",
    value: "LKR",
  },
  {
    label: "Sudan",
    value: "SDG",
  },
  {
    label: "Suriname",
    value: "SRD",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "NOK",
  },
  {
    label: "Sweden",
    value: "SEK",
  },
  {
    label: "Switzerland",
    value: "CHF",
  },
  {
    label: "Syria",
    value: "SYP",
  },
  {
    label: "Tajikistan",
    value: "TJS",
  },
  {
    label: "Tanzania",
    value: "TZS",
  },
  {
    label: "Thailand",
    value: "THB",
  },
  {
    label: "The Democratic Republic of Congo",
    value: "CDF",
  },
  {
    label: "Tokelau",
    value: "NZD",
  },
  {
    label: "Tonga",
    value: "TOP",
  },
  {
    label: "Trinidad and Tobago",
    value: "TTD",
  },
  {
    label: "Tunisia",
    value: "TND",
  },
  {
    label: "Turkey",
    value: "TRY",
  },
  {
    label: "Turkmenistan",
    value: "TMT",
  },
  {
    label: "Uganda",
    value: "UGX",
  },
  {
    label: "Ukraine",
    value: "UAH",
  },
  {
    label: "United Arab Emirates",
    value: "AED",
  },
  {
    label: "United Kingdom",
    value: "GBP",
  },
  {
    label: "United States",
    value: "USD",
  },
  {
    label: "Uruguay",
    value: "UYU",
  },
  {
    label: "Uzbekistan",
    value: "UZS",
  },
  {
    label: "Vanuatu",
    value: "VUV",
  },
  {
    label: "Venezuela",
    value: "VEF",
  },
  {
    label: "Vietnam",
    value: "VND",
  },
  {
    label: "Western Sahara",
    value: "MAD",
  },
  {
    label: "Yemen",
    value: "YER",
  },
  {
    label: "Zambia",
    value: "ZMW",
  },
  {
    label: "Zimbabwe",
    value: "ZWD",
  },
];
