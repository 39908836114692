import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { PlusIcon, SymbolIcon } from "@radix-ui/react-icons";
import styled from "styled-components";

import { Button } from "./common/ui/button";
import { useToast } from "./common/ui/use-toast";
import { getUser, createBusiness } from "../services";
import { capitalizeFirstLetter } from "src/helpers/utils";
import AddBusiness from "./AddBusiness";
import useReactForm from "../hooks/useForm";
import { getCountries, getBusinesses, getBusinessTypes } from "../services";
import {
  TranferTitle,
  TransferCardContainer,
  TransferIcon,
  TypeWrapper,
  WalletContainer,
  WalletGrid,
  WalletName,
  WalletNumber,
  WalletType,
} from "./Styles";
import BusinessWallet from "./BusinessWallet";
import InitiateTransfer from "./common/InitiateTransfer";

const Business = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: userData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const {
    data: businessData,
    refetch: refetchBusinessData,
    isLoading: businessesIsLoading,
    isError: errorLoadingIsBusiness,
    isSuccess: businessDataSuccess,
  } = useQuery({
    queryKey: ["businessDetails", userData?.id],
    queryFn: () => handleGetBusinessData(userData?.id),
    enabled: !!userData?.id,
  });

  const businessDataResponse = businessData || [];

  const [showAddBusiness, setShowAddBusiness] = useState(false);
  const [businessInFocus, setBusinessInFocus] = useState(businessDataResponse[0]?.id);
  const [openInitiateTransfer, setOpenInitiateTransfer] = useState(false);

  const { reset } = useReactForm();

  const handleGetBusinessData = async (userId: string) => {
    try {
      return await getBusinesses(userId);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load business.",
      });
    }
  };

  useEffect(() => {
    if (businessDataSuccess) {
      setBusinessInFocus(businessDataResponse[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessDataSuccess]);

  const { data: businessTypes, isLoading: businessTypesIsLoading } = useQuery({
    queryKey: ["businessTypes"],
    queryFn: getBusinessTypes,
  });

  const { data: countries, isLoading: countriesIsLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: () => getCountries(),
  });

  const userId = userData?.id;

  function handleNavigateToBusiness() {
    navigate(`/home/business/${businessInFocus}`);
  }

  const createBusinessMutation = useMutation({
    mutationKey: ["createBusiness"],
    mutationFn: (payload: any) => createBusiness(userId, payload),
    onSuccess: async () => {
      toast({
        description: "Business has been created successfully.",
      });
      setShowAddBusiness(false);
      await refetchBusinessData();
      reset();
    },
    onError: (error: any) => {
      const errorMessages = error?.response?.data && Object.values(error.response.data).flat();

      toast({
        variant: "destructive",
        title: "Your request encountered the following errors:",
        description: (
          <>
            {Boolean(errorMessages.length) ? (
              errorMessages.map((errorMessage: any, index: number) => (
                <p key={index}>- {capitalizeFirstLetter(errorMessage)}</p>
              ))
            ) : (
              <p>Sorry, an error occured whilst creating busineses.</p>
            )}
          </>
        ),
      });
    },
  });

  return (
    <>
      {!userData?.has_business ? (
        <EmptyComponent>
          <p>You have no business account yet. Click to add a business.</p>
          <Button
            onClick={() => setShowAddBusiness(true)}
            className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
          >
            <PlusIcon className="mr-1" /> Add Business
          </Button>
        </EmptyComponent>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <TransferCardContainer onClick={() => setOpenInitiateTransfer(true)}>
              <TransferIcon>
                <SymbolIcon width={25} height={25} />
              </TransferIcon>
              <TranferTitle>Initiate Business Transfer</TranferTitle>
              {/* <TransferDescription>{description}</TransferDescription> */}
            </TransferCardContainer>
            <Button
              onClick={() => setShowAddBusiness(true)}
              className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
            >
              <PlusIcon className="mr-1" /> Add Business
            </Button>
          </div>

          {businessesIsLoading ? (
            <EmptyComponent>
              <h6>Loading business...</h6>
            </EmptyComponent>
          ) : errorLoadingIsBusiness ? (
            <EmptyComponent>
              <h6>An error occured whilst loading busineses.</h6>
              <Button
                onClick={() => refetchBusinessData()}
                className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
              >
                Try again
              </Button>
            </EmptyComponent>
          ) : (
            <>
              <WalletGrid style={{ marginTop: "2rem", gap: 30 }} business="business">
                {Boolean(businessData) &&
                  businessData?.map(
                    ({
                      id,
                      name,
                      email,
                      country,
                    }: {
                      id: string;
                      name: string;
                      email: string;
                      country: string;
                    }) => (
                      <WalletContainer
                        isActive={id === businessInFocus}
                        onClick={() => setBusinessInFocus(id)}
                      >
                        <WalletName>{name}</WalletName>
                        <WalletNumber>{email}</WalletNumber>

                        <TypeWrapper>
                          <WalletType>{country}</WalletType>
                        </TypeWrapper>
                      </WalletContainer>
                    ),
                  )}
              </WalletGrid>
              {Boolean(businessData) && <BusinessWallet businessId={businessInFocus} />}
              <Button
                onClick={handleNavigateToBusiness}
                style={{ background: "#fff5f0", color: "#B52C2C", marginTop: 20 }}
                className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
              >
                See KYC details
              </Button>
            </>
          )}
        </div>
      )}
      <InitiateTransfer
        open={openInitiateTransfer}
        handleClose={() => setOpenInitiateTransfer(false)}
        businesses={businessData}
        isLoadingBusiness={businessesIsLoading}
        isBusinessTransfer
      />

      <AddBusiness
        open={showAddBusiness}
        handleClose={() => setShowAddBusiness(false)}
        countries={countries}
        countriesIsLoading={countriesIsLoading}
        createBusinessMutation={createBusinessMutation}
        isLoading={createBusinessMutation.isPending}
        businessTypes={businessTypes}
        businessTypesIsLoading={businessTypesIsLoading}
      />
    </>
  );
};

const EmptyComponent = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 50vw;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export default Business;
