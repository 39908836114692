import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import * as Yup from "yup";
type UserData = {
  email: string;
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const useForgotPassword = () => {
  const initialValues = {
    email: "",
  };
  const navigate = useNavigate();

  const resetPassword = async (userData: UserData) => {
    console.log({ userData });
    try {
      const response = await fetch(`${BASE_URL}/auth/user/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (!response.ok) {
        let errorMessage = "An unknown error occurred";
        Object.keys(data).forEach((propertyName) => {
          if (Array.isArray(data[propertyName])) {
            errorMessage = data[propertyName][0];
            toast.error(errorMessage);
          }
        });
        throw new Error("Password reset failed");
      }

      return data;
    } catch (error) {
      console.log("Error in Reset Password:", error);
      throw error;
    }
  };

  const ResetPassword = useMutation({
    mutationFn: resetPassword,
    mutationKey: ["Reset password"],
    onSuccess(data) {
      toast.success(data.message);
      navigate("/reset-password");
    },
    onError(error, variables) {
      toast.error(error.message);
      console.log("ON ERROR:", error, variables);
    },
  });

  const handleSubmit = async (
    values: { email: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      setSubmitting(true);
      await ResetPassword.mutateAsync(values);
    } catch (error) {
      console.log("Password reset error", error);
    } finally {
      setSubmitting(false);
    }
  };

  return { handleSubmit, initialValues };
};
