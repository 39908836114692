import React from "react";
import { Close } from "../assests/svgs/CloseIcon";
import { truncateFileName } from "../helpers/utils";

import { FileUploadContainer, UploadInput, FileTitle } from "./Styles";

type FileUploadProps = {
  file?: any;
  onCloseClick: () => void;
  isLoading?: boolean;
};

export default function FileUpload({ file, onCloseClick, isLoading = false }: FileUploadProps) {
  return (
    <FileUploadContainer>
      <UploadInput>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FileTitle>
            {truncateFileName(file?.name) ?? "File"}{" "}
            <span style={{ fontWeight: "normal" }}>
              {isLoading ? "is being uploaded..." : "is ready to be uploaded"}
            </span>
          </FileTitle>
        </div>

        {!isLoading && (
          <div onClick={onCloseClick} style={{ marginLeft: "0.5rem" }}>
            <Close fill="#eee" />
          </div>
        )}
      </UploadInput>
    </FileUploadContainer>
  );
}
