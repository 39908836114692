import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "./ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";

const ConfirmTransfer = ({
  open,
  handleClose,
  handleConfirm,
  handleConfirmClose,
  values,
  selectedCurrency,
  transferMutation,
  confirmationDetails,
  isLoading,
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  handleConfirmClose: () => void;
  values: any;
  selectedCurrency: any;
  transferMutation: any;
  confirmationDetails: any;
  isLoading: boolean;
}) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Confirm Transfer</Dialog.Title>

          {isLoading ? (
            <div style={{ margin: "3rem auto", textAlign: "center" }}>Confirming details...</div>
          ) : confirmationDetails?.error ? (
            <div style={{ margin: "3rem auto", textAlign: "center", fontSize: "0.8rem" }}>
              {confirmationDetails?.errorMessage}
            </div>
          ) : (
            <div>
              <p className="mt-8">
                Are you sure you want to transfer{" "}
                <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  {selectedCurrency?.currency} {values?.amount}{" "}
                </span>
                to{" "}
                <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                  {confirmationDetails?.success?.message}
                </span>
                ?
              </p>
            </div>
          )}

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}></div>
          <Dialog.Close asChild>
            <button
              disabled={transferMutation.isPending}
              className="IconButton"
              aria-label="Close"
              onClick={handleConfirmClose}
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <Button
                onClick={handleConfirm}
                disabled={transferMutation.isPending || isLoading || confirmationDetails?.error}
                type="submit"
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                {transferMutation.isPending ? "Loading..." : "Transfer"}
              </Button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              disabled={transferMutation.isPending}
              onClick={handleConfirmClose}
              className="IconButton"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConfirmTransfer;
