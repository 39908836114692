import { useQuery } from "@tanstack/react-query";
import { getUser } from "src/services";

export function useUserData () {
    const { data: userData } = useQuery({
        queryKey: ["userDetails"],
        queryFn: () => getUser(),
      });

      return {userData}
}