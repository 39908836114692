import { useState } from "react";
import Profile from "./Profile";
import KYCVerification from "./KYCVerification";
import { AccountContainer, AccountNav, AccountNavItem } from "./common/Styles";

const NAVITEMS = [{ name: "Profile" }, { name: "KYC Verification" }];

const Account = () => {
  const [selectedItem, setSelectedItem] = useState<string>(NAVITEMS[0].name);

  const isProfile = selectedItem === NAVITEMS[0].name;

  return (
    <AccountContainer>
      <AccountNav>
        {NAVITEMS?.map(({ name }) => (
          <AccountNavItem
            key={name}
            selectedItem={selectedItem === name ? name : ""}
            onClick={() => setSelectedItem(name)}
          >
            {name}
          </AccountNavItem>
        ))}
      </AccountNav>

      {isProfile ? <Profile /> : <KYCVerification />}
    </AccountContainer>
  );
};

export default Account;
