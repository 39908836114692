import { useQuery } from "@tanstack/react-query";
import { getBusinessWallet } from "src/services";
import {
  LoadingContainer,
  Title,
  TitleWrapper,
  TypeWrapper,
  WalletContainer,
  WalletGrid,
  WalletName,
  WalletNumber,
  WalletType,
} from "./Styles";
import { formatAmount } from "src/helpers/utils";
import { CreditCardIcon } from "lucide-react";
import { useEffect } from "react";

const BusinessWallet = ({ businessId }: { businessId: string }) => {
  const {
    data: businessWalletData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["businessWallet"],
    queryFn: () => getBusinessWallet(businessId),
    enabled: !!businessId,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  return (
    <div>
      <TitleWrapper>
        <Title>Your Business Wallets</Title>
      </TitleWrapper>

      {isLoading && !businessWalletData && (
        <LoadingContainer>
          <p> Loading wallets...</p>
        </LoadingContainer>
      )}

      {isError && !businessWalletData && (
        <LoadingContainer>
          <p>There was an error getting wallets</p>
          <button onClick={() => refetch()} className="text-red-500">
            Click to retry
          </button>
        </LoadingContainer>
      )}
      {businessWalletData && (
        <WalletGrid>
          {businessWalletData?.map(
            ({
              balance,
              currency,
              number,
            }: {
              balance: string;
              currency: string;
              number: string;
            }) => (
              <WalletContainer>
                <WalletName>{formatAmount(balance)}</WalletName>
                <WalletNumber>{number}</WalletNumber>
                <TypeWrapper>
                  <WalletType>{currency}</WalletType>
                  <CreditCardIcon className="h-6 w-6 text-yellow-800" />
                </TypeWrapper>
              </WalletContainer>
            ),
          )}
        </WalletGrid>
      )}
    </div>
  );
};

export default BusinessWallet;
