import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useToast } from "src/components/common/ui/use-toast";
import {
  acceptTradeOrder,
  confirmTradeOrder,
  createP2POrder,
  createTradeRequest,
  createUserPaymentMethods,
  currencyConversion,
  deleteP2pTradeRequest,
  editTradeRequest,
  editUserPaymentMethods,
  getP2PRequests,
  getP2pOrders,
  getPaymentOptions,
  getUserP2PRequests,
  getUserPaymentMethods,
  verifyTradeOTP,
} from "src/services";

export function useTradingRequests({
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  return useQuery({
    queryKey: ["trading-requests"],
    queryFn: () => getP2PRequests({ tradeType, currencyCode, paymentMethods, region, page }),
    refetchOnWindowFocus: false,
  });
}

export function useUserTradingRequests({
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  return useQuery({
    queryKey: ["user-trading-requests"],
    queryFn: () => getUserP2PRequests({ tradeType, currencyCode, paymentMethods, region, page }),
    refetchOnWindowFocus: false,
  });
}

export function useExchangeMutationApi() {
  const { toast } = useToast();

  return useMutation({
    mutationKey: ["convertCurrency"],
    mutationFn: (payload: any) => currencyConversion(payload),
    onError: () => {
      toast({
        description: "An error occured",
      });
    },
  });
}

export function useP2PMutationApi() {
  const { toast } = useToast();

  return useMutation({
    mutationKey: ["convertCurrency"],
    mutationFn: (payload: any) => createP2POrder(payload),
    onSuccess: () => {
      toast({
        description: "Order successfully created",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });
}

export function useP2pOrders({
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  currencyCode?: any;
  paymentMethods?: string;
  region?: string;
  page?: number;
}) {
  return useQuery({
    queryKey: ["p2p-orders"],
    queryFn: () => getP2pOrders({ currencyCode, paymentMethods, region, page }),
    refetchOnWindowFocus: false,
  });
}

export function useCreateTradeRequestMutationApi() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const createRequest = useMutation({
    mutationKey: ["createTradeRequest"],
    mutationFn: (payload: any) => createTradeRequest(payload),
    onSuccess: () => {
      toast({
        description: "Trade request successfully created",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });

  const editRequest = useMutation({
    mutationKey: ["editTradeRequest"],
    mutationFn: ({ requestId, payload }: { requestId: string; payload: any }) =>
      editTradeRequest(requestId, payload),
    onSuccess: () => {
      toast({
        description: "Trade request successfully edited",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });

  const deleteRequest = useMutation({
    mutationKey: ["deleteTradeRequest"],
    mutationFn: (id: string) => deleteP2pTradeRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["trading-requests"] });
      toast({
        description: "Trade request successfully deleted",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.detail || "An error occured",
      });
    },
  });

  return {
    createRequest,
    editRequest,
    deleteRequest,
  };
}

export function useAcceptTradeMutation() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const accepTradeMutationResult = useMutation({
    mutationKey: ["acceptTrade"],
    mutationFn: (payload: { order_id: string; accept: boolean }) => acceptTradeOrder(payload),
    onSuccess: (res) => {
      toast({
        description: res?.message || "Request successful.",
      });
      queryClient.invalidateQueries({ queryKey: ["trading-requests"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.message || "An error occured",
      });
    },
  });

  return { accepTradeMutationResult };
}

export function useConfirmTradeMutationApi() {
  const [confirmTradeResponse, setConfirmTradeResponse] = useState<any>({});
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const mutationResult = useMutation({
    mutationKey: ["confirmTrade"],
    mutationFn: (orderId: string) => confirmTradeOrder(orderId),
    onSuccess: (res) => {
      setConfirmTradeResponse(res);
      toast({
        description: res?.message,
      });
      queryClient.invalidateQueries({ queryKey: ["trading-requests"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });
  return { confirmTradeResponse, mutationResult };
}

export function useVerifyTradeOTPMutationApi() {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["verifyTradeOTP"],
    mutationFn: ({ orderId, otp }: { orderId: string; otp: string }) =>
      verifyTradeOTP(orderId, otp),
    onSuccess: (res) => {
      toast({
        description: res?.message,
      });
      queryClient.invalidateQueries({ queryKey: ["trading-requests"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });
}

export function useCreatePaymentMethodsMutationApi() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const createPaymentMethod = useMutation({
    mutationKey: ["createPaymentMethod"],
    mutationFn: (payload: any) => createUserPaymentMethods(payload),
    onSuccess: () => {
      toast({
        description: "Payment option added succesfully",
      });
      queryClient.invalidateQueries({ queryKey: ["userPaymentMethods"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });

  const editPaymentMethod = useMutation({
    mutationKey: ["createPaymentMethod"],
    mutationFn: ({ id, payload }: { id: string; payload: any }) =>
      editUserPaymentMethods(id, payload),
    onSuccess: () => {
      toast({
        description: "Payment option edited succesfully",
      });
      queryClient.invalidateQueries({ queryKey: ["userPaymentMethods"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });

  return { createPaymentMethod, editPaymentMethod };
}

export function useGetUserPaymentMethods() {
  return useQuery({
    queryKey: ["userPaymentMethods"],
    queryFn: () => getUserPaymentMethods(),
    refetchOnWindowFocus: false,
  });
}

export function useGetPaymentOptions() {
  return useQuery({
    queryKey: ["paymentOptions"],
    queryFn: () => getPaymentOptions(),
    refetchOnWindowFocus: false,
  });
}
