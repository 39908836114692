import { useState, useEffect } from "react";
import { SymbolIcon, ListBulletIcon, CardStackIcon, ExternalLinkIcon } from "@radix-ui/react-icons";

import { Input } from "../common/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/ui/table";
import Rechart from "../common/Rechart";
import CompleteActivation from "../common/CompleteActivation";
import { CardTitle, TranferTitle, Wrapper } from "../Styles";
import {
  TitleWrapper,
  Title,
  TitleDescription,
  TransferContainer,
  TableWrapper,
  TableHeading,
  TableContainer,
  DashboardCardContainer,
  DashboardCardTitleContainer,
  DashboardCardMain,
  DashboardCardDescription,
} from "../Styles";

import { ComingSoon } from "../Styles";

import { useQuery } from "@tanstack/react-query";

import TransferDetails from "../common/TransferDetails";
import { getTransferHistory, getUser } from "src/services";
import Banner from "../common/Banner";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { Statuses, TransactionTypes } from "src/helpers/constants";
import { useToast } from "../common/ui/use-toast";
import { getBusinesses } from "../../services";

export const Business = (props: any) => {
  const { businessData, businessesIsLoading } = props;
  const [openActivation, setOpenActivation] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [page, setPage] = useState(1);
  const [openTransferDetail, setOpenTransferDetail] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [businessType, setBusinessType] = useState<any>([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>(null);

  const { toast } = useToast();

  const { data: userData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
    enabled: !!JSON.stringify(localStorage.getItem("userData")),
  });

  useEffect(() => {
    if (businessData?.length) {
      const getBusiness = businessData?.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      setBusinessType(getBusiness);
      
      if (getBusiness.length > 0) {
        setSelectedBusinessType(getBusiness[0].value);
      }
    }
  }, [businessData]);

  const {
    data: transferHistory,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["businesstransferHistory", selectedBusinessType],
    queryFn: () => businessData?.length && getTransferHistory(selectedBusinessType, page, filters),
    enabled: !!userData?.id && businessData?.length > 0,
  });
  console.log("businessData", businessData);
  
  const isPendingActivation = !(
    userData?.user_images?.profile_picture && userData?.user_images?.user_id_image
  );

  useEffect(() => {
    if (userData?.id) {
      refetch();
    }
  }, [page, refetch, userData, filters]);

  function handleActionClose() {
    setOpenActivation(false);
  }

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleStatusSelect = (event: any) => {
    setPage(1);
    event?.value === "all"
      ? setFilters((prevFilters: any) => ({ ...prevFilters, status: null }))
      : setFilters((prevFilters: any) => ({ ...prevFilters, status: event?.value }));
  };

  const handleTransactionTypeSelect = (event: any) => {
    setPage(1);
    event?.value === "all"
      ? setFilters((prevFilters: any) => ({ ...prevFilters, transaction_type: null }))
      : setFilters((prevFilters: any) => ({ ...prevFilters, transaction_type: event?.value }));
  };

  const handleTransactionBusinessTypeSelect = (event: any) => {
    setPage(1);
    setSelectedBusinessType(event?.value);
    event?.value === "all"
      ? setFilters((prevFilters: any) => ({
          ...prevFilters,
          business_type: null,
        }))
      : setFilters((prevFilters: any) => ({ ...prevFilters, business_type: event?.value }));

    refetch();
  };

  function handleRowClick(props: any) {
    setOpenTransferDetail(true);
    setSelectedRowItem(props);
  }

  return (
    <div>
      <TableContainer>
        <TableHeading>
          <div>
            <CardTitle>Business history</CardTitle>
            <TitleDescription>A list of all your transfer history.</TitleDescription>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="grid w-[220px] max-w-sm items-center gap-1.5 m-1rem mr-3 mt-5">
              <Select
                onChange={handleTransactionBusinessTypeSelect}
                options={businessType}
                isClearable={true}
                placeholder="Business types"
                isDisabled={isFetching}
                value={
                  businessType.find(
                    (option: { value: string; label: string }) =>
                      option.value === selectedBusinessType,
                  ) || null
                }
              />
            </div>
            <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mt-5 mr-3">
              <Select
                onChange={handleStatusSelect}
                options={Statuses}
                isClearable={true}
                placeholder="Filter by status"
                isDisabled={isFetching}
              />
            </div>

            <div className="grid w-[250px] max-w-sm items-center gap-1.5 m-1rem mt-5">
              <Select
                onChange={handleTransactionTypeSelect}
                options={TransactionTypes}
                isClearable={true}
                placeholder="Transaction types"
                isDisabled={isFetching}
              />
            </div>
          </div>
        </TableHeading>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Type</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Currency</TableHead>
                <TableHead>Wallet number</TableHead>
                <TableHead className="text-right">Channel</TableHead>
                <TableHead>Mode</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Charges</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {businessesIsLoading || isFetching ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : isError ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Error fetching data
                  </TableCell>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    <button onClick={() => refetch()}>Refetch</button>
                  </TableCell>
                </TableRow>
              ) : businessData?.length === 0 || transferHistory?.results?.length === 0 ? (
                <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                  Ooops, there are no items at the moment.
                </TableCell>
              ) : (
                transferHistory?.results?.map((props: any) => {
                  const {
                    transaction_type,
                    amount,
                    currency_code,
                    channel,
                    status,
                    wallet_number,
                    charges,
                    debit_or_credit,
                    reference,
                    description,
                    receiver_wallet_number,
                    sender_wallet_number,
                  } = props;
                  return (
                    <TableRow key={uuidv4()} onClick={() => handleRowClick(props)}>
                      <TableCell className="font-medium">{transaction_type}</TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{currency_code}</TableCell>
                      <TableCell>
                        {debit_or_credit === "Debit"
                          ? receiver_wallet_number
                          : sender_wallet_number}
                      </TableCell>
                      <TableCell className="text-right">{channel}</TableCell>
                      <TableCell>{debit_or_credit}</TableCell>
                      <TableCell>{status}</TableCell>
                      <TableCell>{charges}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={handlePrevPage}
            disabled={page === 1 || !transferHistory?.previous}
            className={`btn ${
              page === 1 || !transferHistory?.previous ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {"<<  Previous"}
          </button>
          <span className="mx-4">Page {page}</span>
          <button
            onClick={handleNextPage}
            disabled={!transferHistory?.next}
            className={`btn ${!transferHistory?.next ? "cursor-not-allowed opacity-50" : ""}`}
          >
            {"Next >>"}
          </button>
        </div>
      </TableContainer>

      <TransferDetails
        open={openTransferDetail}
        handleClose={() => setOpenTransferDetail(false)}
        details={selectedRowItem}
      />
    </div>
  );
};
