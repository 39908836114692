import Cookies from "js-cookie";

import { useEffect, useState } from "react";

export function useCookie(name: string, initialValue: any) {
  const [value, setValue] = useState(Cookies.get(name) || initialValue);

  const setCookie = (newValue: string, options: Cookies.CookieAttributes | undefined) => {
    Cookies.set(name, newValue, options);
    setValue(newValue);
  };

  const removeCookie = () => {
    Cookies.remove(name);
    setValue(null);
  };

  return [value, setCookie, removeCookie];
}
