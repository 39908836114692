import * as Dialog from "@radix-ui/react-dialog";

import styled from "styled-components";
import { Button } from "./ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";
import { FormItem } from "./ui/form";
import { Input } from "./ui/input";
import { useEffect, useState } from "react";
import { useP2pOrders, useVerifyTradeOTPMutationApi } from "src/hooks/useTrading";
import { Label } from "./ui/label";
import { useB2bOrders } from "src/hooks/useB2bTrading";

const ConfirmTradeOTPModal = ({
  open,
  handleClose,
  id,
  businessId,
}: {
  open: boolean;
  handleClose: () => void;
  id: string;
  businessId: string;
}) => {
  const [otp, setOTP] = useState("");

  const { refetch } = useP2pOrders({});
  const { refetch: refetchBusinessOrders } = useB2bOrders({ businessId: businessId });

  const handleOTPChange = (otp: string) => {
    setOTP(otp);
  };

  const verifyOTPMutation = useVerifyTradeOTPMutationApi();

  function handleVerifyOTP() {
    verifyOTPMutation.mutate({ orderId: id, otp });
  }

  useEffect(() => {
    if (verifyOTPMutation.isSuccess) {
      refetch();
      refetchBusinessOrders();
      handleClose();
    }
  }, [verifyOTPMutation.isSuccess]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <DialogContent className="DialogContentSmall">
          <Dialog.Title className="DialogTitle">Confirm Trade OTP</Dialog.Title>

          <div style={{ display: "flex", flexDirection: "column", marginTop: 25 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormItem className="mt-5">
                <Label>Trade Order verification code</Label>
                <Input
                  name="trade_order_verification_code"
                  onChange={(e) => handleOTPChange(e.target.value)}
                />
              </FormItem>
            </div>
          </div>
          <Dialog.Close asChild>
            <CloseIconButton className="IconButton" aria-label="Close" onClick={handleClose}>
              <Cross2Icon width="20" height="20" />
            </CloseIconButton>
          </Dialog.Close>
          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <StyledButton
                onClick={handleVerifyOTP}
                type="submit"
                disabled={otp === ""}
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Confirm
              </StyledButton>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <CloseIconButton onClick={handleClose} className="IconButton" aria-label="Close">
              <Cross2Icon width="20" height="20" />
            </CloseIconButton>
          </Dialog.Close>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const CloseIconButton = styled.button`
  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const DialogContent = styled(Dialog.Content)`
  @media print {
    width: 100vw;
    heigt: 100vh;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 0.5rem;
`;

const Item = styled.div`
  width: 50%;
`;

export default ConfirmTradeOTPModal;
