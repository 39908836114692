import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center px-8 pt-28 items-start h-screen bg-gray-100">
      <div className="card justify-center flex flex-col">
        <div className="rounded-full h-40 w-40 flex items-center justify-center bg-gray-200 mx-auto mb-6">
          <span className="text-green-600 text-8xl">✓</span>
        </div>
        <h1 className="text-green-600 font-semibold text-center text-4xl mb-2">Success</h1>
        <p className="text-gray-700 text-center text-lg">
          Your account was created successfully
          <br /> Sign in to your account on the next screen to go to your dashboard.
        </p>
        <div className="mt-6">
          <button
            type="submit"
            onClick={() => navigate("/")}
            className="flex w-full justify-center py-3 rounded-md bg-gray-900 px-3 text-md font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
          >
            Continue to Sign in
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
