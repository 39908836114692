import * as Yup from "yup";

export const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .max(30, "Email is limited to 100 characters.")
    .trim()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address")
    .required("Required"),
  password: Yup.string()
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .min(8, "Password must be at least 8 characters long")
    .max(100, "Limited to 100 characters")
    .required("Required"),
  firstName: Yup.string()
    .trim()
    .min(2, "First name should be at least 2 characters")
    .max(30, "First name limited to 30 characters only.")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .max(30, "Last name limited to 30 characters only.")
    .min(2, "Last name should be at least 2 characters")
    .required("Required"),
  phone: Yup.string()
    .trim()
    .matches(/^\d{10,15}$/, "Invalid phone number")
    .required("Required"),
  country: Yup.string().required("Required"),
});

export const siginInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .max(100, "Email is limited to 100 characters.")
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address")
    .required("Required"),
  password: Yup.string()
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .min(8, "Password must be at least 8 characters long")
    .required("Required"),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .max(100, "Email is limited to 100 characters.")
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address")
    .required("Email is required"),
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{6}$/, "OTP must be 6 digits"),
  password: Yup.string()
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .min(8, "Password must be at least 8 characters long")
    .max(255)
    .required("Password is required"),
});
