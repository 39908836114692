import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Label } from "./common/ui/label";
import { Input } from "./common/ui/input";
import { Button } from "./common/ui/button";

import "./custom-styles.css";
import { FormProvider, useForm } from "react-hook-form";
import useReactForm from "../hooks/useForm";
import { FormItem, FormMessage } from "./common/ui/form";
import validate from "../helpers/validate";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./common/ui/select";

type AddBusinessProps = {
  open: boolean;
  handleClose: () => void;
  countries: any;
  countriesIsLoading: boolean;
  createBusinessMutation: any;
  isLoading: boolean;
  businessTypes: any;
  businessTypesIsLoading: boolean;
};

function AddBusiness({
  open,
  handleClose,
  countries,
  countriesIsLoading,
  createBusinessMutation,
  isLoading,
  businessTypes,
  businessTypesIsLoading,
}: AddBusinessProps) {
  const methods = useForm();

  const { values, errors, setValues, reset, handleChange } = useReactForm(validate);

  const disableAddBusiness =
    isLoading ||
    !Boolean(Object.values(errors)) ||
    !values?.name ||
    !values?.description ||
    !values?.email ||
    !values?.phone ||
    !values?.country ||
    !values?.id_director_one ||
    !values?.id_director_two ||
    !values?.address ||
    !values?.size ||
    !values?.transaction_volume ||
    !values?.type;

  function handleCreateBusiness() {
    createBusinessMutation.mutate(values);
  }

  const handleCountrySelect = (event: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      country: event,
    }));
  };

  const handleBusinessTypeSelect = (event: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      type: event,
    }));
  };

  function handleModalClose() {
    handleClose();
    reset();
  }

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Create a business</Dialog.Title>

          <div style={{ marginTop: "1rem" }}>
            <FormProvider {...methods}>
              <FormItem>
                <Label>Business Name</Label>
                <Input
                  name="name"
                  placeholder="eg: John Doe"
                  onChange={handleChange}
                  defaultValue={values?.name || ""}
                />
                {errors?.name && <FormMessage>{errors?.nameErrorMessage}</FormMessage>}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Description</Label>
                <Input
                  type="text"
                  name="description"
                  onChange={handleChange}
                  defaultValue={values.description || ""}
                />
                {errors?.description && (
                  <FormMessage>{errors?.descriptionErrorMessage}</FormMessage>
                )}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Business type</Label>

                <Select
                  disabled={businessTypesIsLoading}
                  onValueChange={(event) => handleBusinessTypeSelect(event)}
                >
                  <SelectTrigger
                    className="w-full"
                    onChange={(event: any) => handleBusinessTypeSelect(event)}
                  >
                    <SelectValue placeholder="select business type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {businessTypes?.map(({ name, id }: { name: string; id: string }) => (
                        <SelectItem key={id} value={id}>
                          {name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>

              <FormItem className="mt-3">
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  defaultValue={values?.email || ""}
                />
                {errors?.email && <FormMessage>{errors?.emailErrorMessage}</FormMessage>}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Phone number</Label>
                <Input
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  defaultValue={values.phone || ""}
                />
                {errors?.phone && <FormMessage>{errors?.phoneErrorMessage}</FormMessage>}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Country</Label>

                <Select
                  disabled={countriesIsLoading}
                  onValueChange={(event) => handleCountrySelect(event)}
                >
                  <SelectTrigger
                    className="w-full"
                    onChange={(event: any) => handleCountrySelect(event)}
                  >
                    <SelectValue placeholder="select country" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {countries?.map(({ name, id }: { name: string; id: string }) => (
                        <SelectItem key={id} value={id}>
                          {name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>

              <FormItem className="mt-3">
                <Label>First director name</Label>
                <Input
                  name="id_director_one"
                  placeholder="eg: John Doe"
                  onChange={handleChange}
                  defaultValue={values.id_director_one || ""}
                />
                {errors?.id_director_one && (
                  <FormMessage>{errors?.idDirectorOneErrorMessage}</FormMessage>
                )}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Second director name</Label>
                <Input
                  name="id_director_two"
                  placeholder="eg: John Doe"
                  onChange={handleChange}
                  defaultValue={values.id_director_two || ""}
                />
                {errors?.id_director_two && (
                  <FormMessage>{errors?.idDirectorTwoErrorMessage}</FormMessage>
                )}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Address</Label>
                <Input
                  type="text"
                  name="address"
                  onChange={handleChange}
                  defaultValue={values.address || ""}
                />
                {errors?.address && <FormMessage>{errors?.addressErrorMessage}</FormMessage>}
              </FormItem>

              <FormItem className="mt-3">
                <Label>
                  Company Size{" "}
                  <span style={{ fontSize: "0.7rem" }}>(number of people in the company)</span>
                </Label>
                <Input
                  type="number"
                  name="size"
                  min={1}
                  onChange={handleChange}
                  defaultValue={values.size || ""}
                />
                {errors?.size && <FormMessage>{errors?.sizeErrorMessage}</FormMessage>}
              </FormItem>

              <FormItem className="mt-3">
                <Label>Monthly transaction volume</Label>
                <Input
                  type="number"
                  name="transaction_volume"
                  onChange={handleChange}
                  defaultValue={values.transaction_volume || ""}
                />
                {errors?.transaction_volume && (
                  <FormMessage>{errors?.transactionVolumeErrorMessage}</FormMessage>
                )}
              </FormItem>
            </FormProvider>
          </div>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}></div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={handleModalClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <Button
                type="submit"
                onClick={handleCreateBusiness}
                disabled={disableAddBusiness}
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                {isLoading ? "Adding business..." : "Add business"}
              </Button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button onClick={handleModalClose} className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default AddBusiness;
