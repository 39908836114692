import * as React from "react";
import { cn } from "../../../lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    // Function to handle input and restrict non-numeric characters only for tel type
    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      if (type === "tel") {
        const inputValue = e.currentTarget.value;
        const sanitizedValue = inputValue.replace(/[^0-9()+\s]/g, "");
        e.currentTarget.value = sanitizedValue;
      }
    };

    return (
      <input
        type={type}
        className={cn(
          "appearance-none flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
        onInput={handleInput}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
