export const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_WALLET_URL = process.env.REACT_APP_WALLET_URL;
export const REACT_APP_PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
export const REACT_APP_PAYMENT_KEY = process.env.REACT_APP_PAYMENT_KEY;
export const REACT_APP_WALLET_KEY = process.env.REACT_APP_WALLET_KEY;

export enum NAV_ITEMS {
  TRANSFER = "Transfer",
  WALLETS = "Wallets",
  HISTORY = "History",
  MARKETPLACE = "Hanymarket",
  BUSINESS = "Business",
  TRADING = "Trading",
  BUSINESS_TRADING = "Business trade",
  ACCOUNT = "Account",
}

export const currencies = [
  { label: "AKL Lumi", value: "AKL" },
  { label: "US Dollar", value: "USD" },
  { label: "Euro", value: "EUR" },
  { label: "British Pound", value: "GBP" },
  { label: "Japanese Yen", value: "JPY" },
  { label: "South African Rand", value: "ZAR" },
  { label: "Nigerian Naira", value: "NGN" },
  { label: "Kenyan Shilling", value: "KES" },
  { label: "Ghanaian Cedi", value: "GHS" },
];

export const wallets = [
  { label: "Visa card / Credit card", value: "card" },
  { label: "Mobile money", value: "mobile-money" },
  { label: "Lumi card", value: "lumi-card" },
];

export const Statuses = [
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Failed", value: "failed" },
  { label: "All", value: "all" },
];

export const TransactionTypes = [
  { label: "Transfer", value: "Transfer" },
  { label: "Purchase", value: "Purchase" },
  { label: "Refund", value: "Refund" },
  { label: "Charge back", value: "Chargeback" },
];
