import { useRef, useState, ChangeEvent, KeyboardEvent, ClipboardEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function useVerifyEmail() {
  const [timer, setTimer] = useState(0);
  const [otpValues, setOtpValues] = useState<Array<string>>(Array(6).fill(""));
  const location = useLocation();
  const user = location.state;

  console.log("USER:", user);

  const navigate = useNavigate();

  const inputs = useRef<Array<HTMLInputElement | null>>(
    Array(6)
      .fill(null)
      .map(() => null),
  );

  const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/, "");
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < inputs.current.length - 1) {
      inputs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && index > 0 && !inputs.current[index]?.value) {
      inputs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 6) {
      const newOtpValues = paste.split("").map((char) => char.replace(/\D/, ""));
      setOtpValues(newOtpValues);
      newOtpValues.forEach((value, index) => {
        if (inputs.current[index]) {
          inputs.current[index]!.value = value;
        }
      });
      inputs.current[5]?.focus();
    }
  };

  const resendOTP = async () => {
    if (timer > 0) return;
    setTimer(60);
    try {
      const response = await fetch(`${BASE_URL}/common/user/${user.user_id}/resend-otp/`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        const errorData = await response.json();
        Object.values(errorData).forEach((errorMessage) => {
          console.log(errorMessage);
          const error = JSON.stringify(errorMessage);
          return toast.error(error);
        });
        throw new Error("Resend OTP Error: " + response.status);
      }

      toast.success("OTP resent Successfully");
    } catch (error) {
      console.error("Resend OTP Error:", error);
    }
  };

  const verifyEmail = async () => {
    if (otpValues.every((value) => value)) {
      try {
        const response = await fetch(`${BASE_URL}/auth/verify-email/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_id: user?.user_id, otp: otpValues.join("") }),
        });

        const data = await response.json();
        console.log("SERVER RESPONDED WITH:", data);
        if (!response.ok) {
          const errorMessage = data;
          toast.error(errorMessage);
          throw new Error(errorMessage);
        }

        toast.success("Email Verified Successfully");
        navigate("/account-success");
      } catch (error) {
        console.error("VERIFY EMAIL ERROR:", error);
        toast.error(JSON.stringify(error));
      }
    } else {
      toast.error("Please provide OTP that was sent to your email.");
    }
  };

  const resendOTPMutation = useMutation({
    mutationKey: ["Resend OTP"],
    mutationFn: resendOTP,
  });

  const verifyOtpMutation = useMutation({
    mutationKey: ["Verify OTP"],
    mutationFn: verifyEmail,
  });

  const handleResendOTP = async () => {
    try {
      setOtpValues(Array(6).fill(""));
      await resendOTPMutation.mutateAsync();
    } catch (error) {
      console.error("OTP resend Error:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      await verifyOtpMutation.mutateAsync();
    } catch (error) {
      console.error("Email verification error:", error);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  return {
    timer,
    otpValues,
    inputs,
    handleChange,
    handleKeyDown,
    handlePaste,
    handleResendOTP,
    handleSubmit,
  };
}
