import { Link } from "react-router-dom";
import logo from "../../assests/images/logo-dark-v.png";
import loginBg from "../../assests/images/register-bg.jpg";
import { Formik, ErrorMessage, Field, Form } from "formik";
import { useSignIn } from "./hooks/useSignIn";
import { siginInValidationSchema } from "./helpers/validation";

export default function SignIn() {
  const { initialValues, handleSignIn } = useSignIn();

  return (
    <div className="flex min-h-full flex-col lg:flex-row h-screen">
      <div className="w-full max-w-lg mx-auto p-4 overflow-y-auto h-[100vh] lg:max-w-lg lg:w-auto lg:mx-0 lg:flex-1 lg:justify-center">
        <div className="items-center mt-20 flex flex-col">
          <img className="mix-blend-multiply" src={logo} alt="logo" width={150} height={150} />
          <h2 className="mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-500">
            Don't have an account yet?{" "}
            <Link to="/signup" className="font-semibold text-red-600 hover:text-red-500">
              Sign Up
            </Link>
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={siginInValidationSchema}
          onSubmit={handleSignIn}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <div className="mt-10">
                  <div className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <Field
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-gray-200"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-3 block text-sm leading-6 text-gray-700"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm leading-6">
                        <Link
                          to="/forgot-password"
                          className="font-semibold text-red-600 hover:text-red-500"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="flex w-full justify-center py-3 rounded-md bg-gray-900 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      >
                        {isSubmitting ? "Signing in..." : "Sign in"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="relative min-h-svh hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={loginBg}
          alt="login_background"
        />
      </div>
    </div>
  );
}
