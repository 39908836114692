import { useState } from "react";
import { Wrapper } from "./Styles";
import { AccountNav, AccountNavItem } from "./common/Styles";
import Conversion from "./common/Conversion";
import TradeRequests from "./common/TradeRequests";
import BusinessTrading from "./common/BusinessTrading";

interface TabItem {
  name: string;
}

const TRADENAVITEMS: TabItem[] = [
  { name: "Exchange" },
  { name: "P2P Trade" },
  { name: "B2B Trade" },
];

interface RenderTabs {
  [key: string]: {
    component: JSX.Element;
  };
}

const Trading = () => {
  const [selectedItem, setSelectedItem] = useState<string>(TRADENAVITEMS[0].name);

  const renderTabs: RenderTabs = {
    Exchange: { component: <Conversion /> },
    "P2P Trade": { component: <TradeRequests /> },
    "B2B Trade": { component: <BusinessTrading /> },
  };

  return (
    <Wrapper style={{ marginTop: "2em" }}>
      <AccountNav>
        {TRADENAVITEMS?.map(({ name }) => (
          <AccountNavItem
            key={name}
            selectedItem={selectedItem === name ? name : ""}
            onClick={() => setSelectedItem(name)}
          >
            {name}
          </AccountNavItem>
        ))}
      </AccountNav>
      {renderTabs[selectedItem].component}
    </Wrapper>
  );
};

export default Trading;
