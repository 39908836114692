import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Label } from "@radix-ui/react-label";
import { FormProvider, useForm } from "react-hook-form";
import { Pencil2Icon } from "@radix-ui/react-icons";
import { TrashIcon } from "@radix-ui/react-icons";
import { Cross2Icon } from "@radix-ui/react-icons";

import useReactForm from "../../hooks/useForm";
import validate from "src/helpers/validate";
import "../custom-styles.css";
import { TableWrapper } from "../Styles";
import { Button } from "./ui/button";
import { useToast } from "../common/ui/use-toast";
import { getUser, getUserId, getParticularUser, bulkTransfer } from "src/services";
import { v4 as uuidv4 } from "uuid";
import { Input } from "./ui/input";
import { EmptyMessage } from "./Styles";
import { TransferDescription } from "../Styles";
import { FormItem, FormMessage } from "./ui/form";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../common/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const BulkSend = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { values, errors, setValues, reset, handleChange } = useReactForm(validate);

  const [allTransfers, setAllTransfers] = useState<any>([]);
  const [confirmDetailsIsLoading, setConfirmDetailsIsLoading] = useState<boolean>(false);
  const [openAddTransferItem, setOpenAddTransferItem] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { isLoading: walletIsLoading, data: userData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const handleUserId = async (walletNumber: number) => {
    try {
      return await getUserId(walletNumber);
    } catch (error: any) {
      setConfirmDetailsIsLoading(false);
      toast({
        variant: "destructive",
        title: "Error",
        description: error?.response?.data?.message,
      });
    }
  };

  const handleGetParticularUser = async (userId: number) => {
    try {
      return await getParticularUser(userId);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error?.response?.data?.message,
      });
    }
  };

  const wallets = userData?.wallets;

  function handleAddRow() {
    setOpenAddTransferItem(true);
  }

  const handleWalletSelect = (event: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      sender_wallet_number: event?.number,
      currency: event?.currency,
    }));
  };

  async function handleConfirmDetails() {
    // setConfirmDetailsIsLoading(true);

    const filteredTransfers = allTransfers.filter((transfer: any) => transfer.id !== values?.id);

    isEdit
      ? setAllTransfers([values, ...filteredTransfers])
      : setAllTransfers((prevTransfers: any) => {
          console.log("previous values: ", prevTransfers, "values: ", values);
          return [
            {
              ...values,
              id: uuidv4(),
            },
            ...prevTransfers,
          ];
        });
    setOpenAddTransferItem(false);
    reset();
    setIsEdit(false);

    // try {
    //   const userIdData = await handleUserId(values?.receiver_wallet_number);
    //   if (userIdData?.user_id) {
    //     const receiverData = await handleGetParticularUser(userIdData.user_id);
    //     setConfirmDetailsIsLoading(false);
    //     setValues((prevValues: any) => ({
    //       ...prevValues,
    //       ...receiverData,
    //     }));

    //     toast({
    //       description: "Details were successfully confirmed.",
    //     });
    //   }
    // } catch (error: any) {
    //   setConfirmDetailsIsLoading(false);
    //   toast({
    //     variant: "destructive",
    //     title: "Error",
    //     description: error?.response?.data?.message ?? "Details are incorrect.",
    //   });
    // }
  }

  const bulkTransferMutation = useMutation({
    mutationKey: ["bulkTransfer"],
    mutationFn: (payload: any) => bulkTransfer(payload),
    onSuccess: async () => {
      toast({ description: "Your bulk transfer was successful" });
      queryClient.invalidateQueries({ queryKey: ["transferHistory"] });
      queryClient.refetchQueries({ queryKey: ["transferHistoryQueryKey"] });

      handleClose();
      setAllTransfers([]);
    },
    onError: (error: any) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: error?.response?.data?.message ?? "The bulk transfer encountered an error",
      });
    },
  });

  function handleBulkTransfer() {
    bulkTransferMutation.mutate(allTransfers);
  }

  function handleDelete(id: string) {
    const filteredTransfers = allTransfers.filter((transfer: any) => transfer?.id !== id);
    setAllTransfers(filteredTransfers);
  }

  function handleEdit(selectedValue: any) {
    setValues(selectedValue);
    setIsEdit(true);
    setOpenAddTransferItem(true);
  }

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContentLarge">
          <Dialog.Title className="DialogTitle">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <div>
                Bulk Transfer
                <TransferDescription>
                  Send money in bulk to multiple recipients at once.
                </TransferDescription>
              </div>

              <div>
                <button
                  onClick={handleAddRow}
                  type="submit"
                  className="flex items-center my-1 mx-1 justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                >
                  Add transfer
                </button>
              </div>
            </div>
          </Dialog.Title>

          <TableWrapper height="55vh">
            {Boolean(allTransfers.length) ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Amount</TableHead>
                    <TableHead>Currency</TableHead>
                    <TableHead>Wallet number</TableHead>
                    <TableHead>Receiver's name</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {allTransfers?.map((transfer: any) => (
                    <TableRow key={transfer?.id}>
                      <TableCell>{transfer?.amount}</TableCell>
                      <TableCell>{transfer?.currency}</TableCell>
                      <TableCell>{transfer?.receiver_wallet_number}</TableCell>
                      <TableHead>{transfer?.name}</TableHead>
                      <TableHead>
                        <div style={{ display: "flex" }}>
                          <TrashIcon
                            onClick={() => handleDelete(transfer?.id)}
                            style={{ marginRight: "0.5rem" }}
                            width={22}
                            height={22}
                          />
                          <Pencil2Icon
                            onClick={() => handleEdit(transfer)}
                            style={{ marginRight: "0.5rem" }}
                            width={20}
                            height={20}
                          />
                        </div>
                      </TableHead>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyMessage>
                <p>You have no transfers yet. Your added transfers will appear here.</p>
              </EmptyMessage>
            )}
          </TableWrapper>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}></div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={handleClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <button
                onClick={handleBulkTransfer}
                disabled={bulkTransferMutation.isPending}
                type="submit"
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                {bulkTransferMutation.isPending ? "Transfering..." : "Transfer"}
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button onClick={handleClose} className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
      <AddItemModal
        open={openAddTransferItem}
        handleClose={() => {
          setOpenAddTransferItem(false);
          reset();
        }}
        values={values}
        handleChange={handleChange}
        errors={errors}
        handleWalletSelect={handleWalletSelect}
        wallets={wallets}
        walletIsLoading={walletIsLoading}
        confirmDetailsIsLoading={confirmDetailsIsLoading}
        handleConfirmDetails={handleConfirmDetails}
      />
    </Dialog.Root>
  );
};

const AddItemModal = ({
  wallets,
  walletIsLoading,
  values,
  handleChange,
  errors,
  handleWalletSelect,
  open,
  confirmDetailsIsLoading,
  handleConfirmDetails,
  handleClose,
}: any) => {
  const methods = useForm();

  const formatWalletNumber = (walletNumber: string | undefined | null): string => {
    if (!walletNumber) return "";
    return walletNumber
      .replace(/[^\d]/g, "")
      .slice(0, 16)
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Add Transfer Item</Dialog.Title>
          <FormProvider {...methods}>
            <FormItem className="mt-5">
              <Label>Select wallet to transfer*</Label>

              <Select
                disabled={walletIsLoading}
                onValueChange={(event) => handleWalletSelect(event)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue
                    placeholder={
                      walletIsLoading
                        ? "loading wallets..."
                        : Boolean(Object.keys(values).length)
                        ? values.currency
                        : "select wallet"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {wallets?.map((wallet: any) => (
                      <SelectItem key={wallet.currency} value={wallet}>
                        {wallet.currency_value}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormItem>

            <FormItem className="mt-5">
              <Label>Amount to transfer*</Label>
              <Input
                name="amount"
                placeholder="2000"
                onChange={handleChange}
                defaultValue={values?.amount}
                type="number"
                min={1}
              />
              {errors?.amount && <FormMessage>{errors?.amountErrorMessage}</FormMessage>}
            </FormItem>

            <FormItem className="mt-5">
              <Label>Receiver's wallet number*</Label>
              <Input
                type="text"
                name="receiver_wallet_number"
                onChange={handleChange}
                value={formatWalletNumber(values?.receiver_wallet_number || "")}
                maxLength={19}
              />
              {errors?.receiver_wallet_number && (
                <FormMessage>{errors?.receiverWalletNumberErrorMessage}</FormMessage>
              )}
            </FormItem>

            <FormItem className="mt-5">
              <Label>Description</Label>
              <Input
                name="description"
                placeholder="description"
                onChange={handleChange}
                defaultValue={values?.description || ""}
              />
            </FormItem>
          </FormProvider>

          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close" onClick={handleClose}>
              <Cross2Icon />
            </button>
          </Dialog.Close>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <Button
                type="submit"
                disabled={
                  !values?.sender_wallet_number ||
                  !values?.receiver_wallet_number ||
                  (values?.amount !== undefined &&
                    values?.amount !== null &&
                    parseFloat(values?.amount) < 0) ||
                  Boolean(Object.values(errors).length) ||
                  confirmDetailsIsLoading
                }
                onClick={handleConfirmDetails}
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                {confirmDetailsIsLoading ? "Confirming details..." : "Confirm details"}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default BulkSend;
