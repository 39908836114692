import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../../assests/images/logo-dark-v.png";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import registerBg from "../../assests/images/register-bg.jpg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import linkedinIcon from "../../assests/svgs/linkedin.svg";
import facebookIcon from "../../assests/svgs/facebook-176.svg";
import googleIcon from "../../assests/svgs/google.svg";

export type User = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  otherNames: string;
  phone: string;
  country: string;
};

export type Country = {
  id: string;
  code: string;
  name: string;
  currency_code: string;
};

export const initialValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  otherNames: "",
  phone: "",
  country: "",
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .max(30, "Email is limited to 100 characters.")
    .trim()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address")
    .required("Required"),
  password: Yup.string()
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .min(8, "Password must be at least 8 characters long")
    .max(100, "Limited to 100 characters")
    .required("Required"),
  firstName: Yup.string()
    .trim()
    .min(2, "First name should be at least 2 characters")
    .max(30, "First name limited to 30 characters only.")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .max(30, "Last name limited to 30 characters only.")
    .min(2, "Last name should be at least 2 characters")
    .required("Required"),
  phone: Yup.string()
    .trim()
    .matches(/^\d{10,15}$/, "Invalid phone number")
    .required("Required"),
  country: Yup.string().required("Required"),
});

const BASE_URL = process.env.REACT_APP_BASE_URL;

function SignUp() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const navigate = useNavigate();

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${BASE_URL}/common/country`);

      const data = await response.json();
      if (!response.ok && response.status !== 200) {
        Object.keys(data).forEach((fieldName) => {
          const errorMessage = data[fieldName][0];
          toast.error(errorMessage);
        });
        throw new Error("Sign up failed");
      }

      return data;
    } catch (error) {
      console.log("ERROR FETCHING COUNTRIES:", error);
      throw error;
    }
  };

  const {
    data: COUNTRIES,
    isLoading,
    error,
  } = useQuery({ queryKey: ["countries"], queryFn: fetchCountries });

  const userSignUp = async (userData: User) => {
    try {
      const response = await fetch(`${BASE_URL}/auth/signup/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userData.email,
          password: userData.password,
          firstname: userData.firstName,
          lastname: userData.lastName,
          othernames: userData.otherNames,
          phone: userData.phone,
          country: userData.country,
        }),
      });

      const data = await response.json();
      if (!response.ok && response.status !== 201) {
        Object.keys(data).forEach((fieldName) => {
          const errorMessage = data[fieldName][0];
          console.log("SIGNUP REQUEST ERROR:", data);
          toast.error(errorMessage);
        });
        throw new Error("Sign up failed");
      }

      return data;
    } catch (error) {
      console.log("SIGNUP ERROR:", error);
      throw error;
    }
  };

  const signUpUserMutation = useMutation({
    mutationFn: userSignUp,
    mutationKey: ["Signup User"],
    onSuccess(data) {
      navigate("/otp-verify", { state: data });
    },
    onError(error, variables, context) {
      console.error("SignUp error:", error, variables);
    },
  });

  const handleSignUp = async (
    values: User,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    try {
      console.log("SIGNUP VALUES:", values);
      await signUpUserMutation.mutateAsync(values);
    } catch (error) {
      console.error("Registration failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex min-h-full flex-col lg:flex-row h-screen">
      <div className="w-full max-w-lg mx-auto p-4 overflow-y-auto h-[100vh] lg:max-w-lg lg:w-auto lg:mx-0 lg:flex-1 lg:justify-center">
        <div className="items-center mt-20 flex flex-col">
          <img className="mix-blend-multiply" src={logo} alt="logo" width={150} height={150} />
          <h2 className="mt-8 text-xl font-bold leading-9 tracking-tight text-gray-700">
            Create an account
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-500">
            Already have an account?{" "}
            <Link to="/" className="font-semibold text-red-600 hover:text-red-500">
              Sign In
            </Link>
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSignUp}
        >
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <Form>
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <div className="mt-10">
                  <div>
                    <div className="flex flex-col">
                      <div className="mt-2">
                        <label
                          htmlFor="firstName"
                          className="text-sm font-medium leading-6 text-gray-900"
                        >
                          First Name
                        </label>
                        <Field
                          id="firstName"
                          name="firstName"
                          type="text"
                          className="rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6 w-full"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="otherNames"
                          className="mt-4 text-sm font-medium leading-6 text-gray-900"
                        >
                          Other Names
                        </label>
                        <Field
                          id="otherNames"
                          name="otherNames"
                          type="text"
                          className="rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6 w-full"
                        />
                        <ErrorMessage
                          name="otherNames"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="lastName"
                          className="mt-4 text-sm font-medium leading-6 text-gray-900"
                        >
                          Last Name
                        </label>
                        <Field
                          id="lastName"
                          name="lastName"
                          type="text"
                          className="rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6 w-full"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Email Address
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          autoComplete=""
                          className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Password
                        </label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mt-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone Number
                        </label>
                        <Field
                          id="phone"
                          name="phone"
                          type="tel"
                          className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Country
                      </label>
                      <Field
                        id="country"
                        as="select"
                        name="country"
                        value={selectedCountry}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue("country", e.target.value);
                          setSelectedCountry(e.target.value);
                        }}
                        className={`block w-full rounded-md border bg-white px-4 ${
                          errors.country && touched.country ? "border-red-500" : "border-gray-300"
                        } px-4 py-2 shadow-sm ring-1 ring-inset ring-gray-900 bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6`}
                      >
                        <option value="">Select a country...</option>
                        {COUNTRIES?.map((country: Country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="flex justify-center items-center mt-4">
                      <label htmlFor="" className="ml-3 block text-sm leading-6 text-gray-700">
                        By creating an account, I accept the{" "}
                        <Link to="/terms" className="font-semibold text-red-600 hover:text-red-500">
                          Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/privacy-policy"
                          className="font-semibold text-red-600 hover:text-red-500"
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="mt-6">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="flex w-full justify-center py-2 rounded-md bg-gray-900 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      >
                        {isSubmitting ? "Creating account..." : "Create account"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="relative min-h-[50vh] lg:min-h-full w-full lg:w-0 flex-1 hidden lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={registerBg}
          alt="login_background"
        />
      </div>
    </div>
  );
}

export default SignUp;
