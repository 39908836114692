import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const PrivateRoute: React.FC = () => {
  const { user } = useAuth();

  if (user?.access !== "" || user?.refresh !== "") {
    return <Outlet />;
  }

  return <Navigate to="/" replace />;
};
