export const theme = {
  colors: {
    primary: "#EF4444",
    secondary: "#B52C2C", // brown
    accent: "#C0C0C0", // Silver
    background: "#FFFFFF", // White
    highlight: "#FFD700", // Gold
    text: "#000000", //black,
    transition: "#fff5f0",
    textSecondary: "#707070", //'#2e1709',
  },
  fonts: {
    body: "Poppins, Arial, sans-serif",
    heading: "Montserrat, sans-serif",
  },
};
