import { Form, Formik } from "formik";
import logo from "../../assests/images/logo-dark-v.png";
import { useVerifyEmail } from "./hooks/useVerifyEmail";

function VerifyEmail() {
  const {
    timer,
    otpValues,
    inputs,
    handleChange,
    handleKeyDown,
    handlePaste,
    handleResendOTP,
    handleSubmit,
  } = useVerifyEmail();

  const initialValues = {
    user_id: "",
    otp: "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <div className="flex h-full flex-col mt-20 bg-white py-12">
          <div className="relative">
            <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
              <div className="items-center flex flex-col">
                <img
                  className="mix-blend-multiply"
                  src={logo}
                  alt="logo"
                  width={200}
                  height={150}
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Email Verification
                </h2>
                <p className="mt-2 text-center text-md leading-6 text-gray-500">
                  We sent an OTP to your email
                </p>
              </div>
              <div>
                <Form>
                  <div className="flex flex-col space-y-10">
                    <div className="flex flex-row items-center justify-between mx-auto w-full max-w-md">
                      {inputs.current.map((input, index) => (
                        <div className="w-16 h-16" key={index}>
                          <input
                            ref={(el) => (inputs.current[index] = el)}
                            className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                            type="text"
                            maxLength={1}
                            autoComplete="off"
                            value={otpValues[index]}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={handlePaste}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="flex flex-col space-y-5">
                      <div>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-gray-900 hover:bg-gray-800 border-none text-white text-sm shadow-sm"
                        >
                          {isSubmitting ? "Verifying..." : "Verify"}
                        </button>
                      </div>

                      <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                        <p>Didn't receive code?</p>{" "}
                        {timer === 0 ? (
                          <div
                            onClick={handleResendOTP}
                            className="font-semibold cursor-pointer text-[#FF914D] hover:text-[#db684c]"
                          >
                            Resend
                          </div>
                        ) : (
                          <span>{`Resend in ${timer} seconds`}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default VerifyEmail;
