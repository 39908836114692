import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../assests/images/logo-dark-v.png";
import { useResetPassword } from "./hooks/useResetPassword";
import { resetPasswordValidationSchema } from "./helpers/validation";

const ResetPassword = () => {
  const { initialValues, handleSubmit } = useResetPassword();

  return (
    <div className="flex justify-center mt-20 h-full">
      <div className="w-full max-w-sm">
        <div className="mx-auto">
          <div className="items-center flex flex-col">
            <img className="mix-blend-multiply" src={logo} alt="logo" width={150} height={150} />
            <h2 className="mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900">
              Reset your password
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-500">
              Fill the form below to reset your password.
            </p>
          </div>
          <div className="mt-10">
            <Formik
              initialValues={initialValues}
              validationSchema={resetPasswordValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder="Email address"
                          className="w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs mt-1"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="otp"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        OTP
                      </label>
                      <div className="mt-2">
                        <Field
                          id="otp"
                          name="otp"
                          type="otp"
                          autoComplete="otp"
                          placeholder="Enter otp"
                          className="w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="text-red-500 text-xs mt-1"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter new password"
                          autoComplete="current-password"
                          required
                          className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 text-xs mt-1"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="flex w-full justify-center py-3 mt-8 rounded-lg bg-gray-900 transition-colors px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      >
                        {isSubmitting ? "Sending..." : "Reset password"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
