import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { TitleDescription, WalletContainer } from "../Styles";
import { Input } from "./ui/input";
import { useEffect, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import {
  useExchangeMutationApi,
  useGetUserPaymentMethods,
  useP2PMutationApi,
} from "src/hooks/useTrading";
import { Button } from "./ui/button";
import LoadingIndicator from "./LoadingIndicator";
import { useCreateBusinessOrderMutationApi } from "src/hooks/useB2bTrading";

const AddTradeOrders = ({
  open,
  handleClose,
  request,
  businessId,
}: {
  open: boolean;
  handleClose: () => void;
  request: any;
  businessId?: any;
}) => {
  const [buyAmount, setBuyAmount] = useState("");
  const [targetCurrency, setTargetCurrency] = useState("");
  const [showErrorText, setShowErrorText] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState({ id: "" });
  const { data: paymentMethodsResponse } = useGetUserPaymentMethods();

  const { min_amount, max_amount, trade_type, payment_methods } = request;

  const isSellRequest = trade_type === "BUY";
  const isBusiness = Boolean(businessId);

  const convert = useExchangeMutationApi();

  const createOrder = useP2PMutationApi();
  const createBusinessTradeOrder = useCreateBusinessOrderMutationApi();

  const orderPayload = {
    trade_request: request?.id,
    amount: buyAmount,
    currency_code: targetCurrency,
    payment_method: selectedWallet.id,
  };

  function hanndleCreateOrder() {
    if (isBusiness) {
      createBusinessTradeOrder.mutate({
        ...orderPayload,
        business_id: businessId,
      });
    } else {
      createOrder.mutate(orderPayload);
    }
  }

  function handleExchange() {
    convert.mutate({
      amount: buyAmount,
      base_currency_code: targetCurrency,
      target_currency_code: "AKL",
    });
  }

  useEffect(() => {
    if (Boolean(buyAmount) && (buyAmount < min_amount || buyAmount > max_amount)) {
      setShowErrorText(true);
    } else {
      setShowErrorText(false);
    }
  }, [buyAmount, min_amount, max_amount]);

  const convertedAmount = convert.data?.converted_amount;

  useEffect(() => {
    if (!!buyAmount && targetCurrency) handleExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyAmount, targetCurrency]);

  useEffect(() => {
    if (createOrder.isSuccess || createBusinessTradeOrder.isSuccess) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder.isSuccess, createBusinessTradeOrder.isSuccess]);

  useEffect(() => {
    setTargetCurrency(request?.active_currency);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedWallet = (wallet: any) => {
    setSelectedWallet(wallet);
  };

  const userPaymentMethods = paymentMethodsResponse?.results || [];
  const paymentMethodsList = isSellRequest ? userPaymentMethods : payment_methods;

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay style={{ inset: 1000 }} className="DialogOverlay">
          <Dialog.Content style={{ width: 480, height: 550 }} className="DialogContent">
            {createOrder.isPending ? (
              <div className="flex items-center justify-center min-h-80">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <Dialog.Title style={{ marginBottom: 24 }} className="DialogTitle">
                  Make order
                </Dialog.Title>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close" onClick={handleClose}>
                    <Cross2Icon />
                  </button>
                </Dialog.Close>
                <WalletContainer>
                  <TitleDescription>{isSellRequest ? "Sell" : "Pay"}</TitleDescription>
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Input
                      type="text"
                      id="amount"
                      placeholder="Amount"
                      onChange={(event) => setBuyAmount(event.target.value)}
                      defaultValue={buyAmount || ""}
                      style={{ width: 200, marginTop: 16 }}
                    />

                    {isSellRequest ? (
                      <Select
                        onValueChange={(event) => setTargetCurrency(event)}
                        defaultValue={request.active_currency}
                      >
                        <SelectTrigger className="w-[120px]">
                          <SelectValue
                            placeholder="Currency"
                            defaultValue={request.active_currency}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {request.currency_codes?.map((value: string) => (
                            <SelectItem key={value} value={value}>
                              {value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    ) : (
                      <Select
                        onValueChange={(event) => setTargetCurrency(event)}
                        defaultValue={request.active_currency}
                      >
                        <SelectTrigger className="w-[120px]">
                          <SelectValue
                            placeholder="Currency"
                            defaultValue={request.active_currency}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {request.currency_codes?.map((value: string) => (
                            <SelectItem key={value} value={value}>
                              {value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  </div>
                  {showErrorText && (
                    <div
                      style={{ color: "#EB5757", fontSize: "0.7125rem", lineHeight: "1.5rem" }}
                    >{`Amount should between ${min_amount} and ${max_amount}`}</div>
                  )}
                </WalletContainer>
                <WalletContainer style={{ marginTop: 24 }}>
                  {isSellRequest ? (
                    <TitleDescription>Send</TitleDescription>
                  ) : (
                    <TitleDescription>Receive</TitleDescription>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{convertedAmount?.toFixed(2) || "__"}</span>
                    <TitleDescription>AKL</TitleDescription>
                  </div>
                </WalletContainer>
                <WalletContainer style={{ marginTop: 24 }}>
                  <TitleDescription>Select Payment Option</TitleDescription>
                  {paymentMethodsList?.map(
                    ({
                      account_details,
                      payment_option,
                      id,
                    }: {
                      account_details: any;
                      payment_option: any;
                      id: string;
                    }) => (
                      <WalletContainer
                        key={id}
                        style={{
                          marginTop: 24,
                          border:
                            selectedWallet?.id === id ? "1px solid #EB5757" : "1px solid lightgray",
                        }}
                        onClick={() => handleSelectedWallet({ id })}
                      >
                        <div className="flex flex-col text-xs px-2.5">
                          <span>{payment_option?.name}</span>
                          <span>{account_details?.provider_name}</span>
                          <span>{account_details?.account_number}</span>
                          <span>{account_details?.name}</span>
                        </div>
                      </WalletContainer>
                    ),
                  )}
                </WalletContainer>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                  <Button
                    onClick={hanndleCreateOrder}
                    disabled={showErrorText || selectedWallet.id === "" || buyAmount === ""}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
                  >
                    {isSellRequest ? "Sell AKL" : "Buy AKL"}
                  </Button>
                </div>
              </>
            )}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AddTradeOrders;
