import { ArrowRightIcon, Cross2Icon } from "@radix-ui/react-icons";
import { CreditCardIcon } from "lucide-react";
import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import Banner from "./common/Banner";
import Lottie from "react-lottie";
import { useQuery } from "@tanstack/react-query";

import { currencies, wallets } from "../helpers/constants";
import emptyData from "../assests/animations/empty.json";
import { getUser } from "../services";
import { Label } from "./common/ui/label";
import { Input } from "./common/ui/input";
import { formatAmount } from "../helpers/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/common/ui/select";

import {
  Card,
  CardTitle,
  CardsWrapper,
  LoadingContainer,
  CurrencySelectWrapper,
  EmptyContainer,
  EmptyTitle,
  EmptyTitleDescription,
  IconWrapper,
  RateContainer,
  RateContent,
  RateLabel,
  Title,
  TitleDescription,
  TitleWrapper,
  TypeWrapper,
  WalletContainer,
  WalletGrid,
  WalletName,
  WalletNumber,
  WalletType,
  Wrapper,
} from "./Styles";

const animationDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: emptyData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Wallet = () => {
  const {
    isLoading: walletIsLoading,
    isError: walletIsError,
    data: userData,
    refetch,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const [openAddWallets, setOpenAddWallets] = useState(false);

  const wallets = userData?.wallets;
  const isPendingActivation = !(
    userData?.user_images?.profile_picture && userData?.user_images?.user_id_image
  );

  function handleClose() {
    setOpenAddWallets(false);
  }

  return (
    <Wrapper>
      {isPendingActivation && <Banner />}

      <TitleWrapper>
        <Title>Your Wallets</Title>
        <TitleDescription>
          Add your mobile money wallet or bank card (and associated account) to pay.
        </TitleDescription>
      </TitleWrapper>

      {/* <AddWallet /> */}

      {walletIsLoading && !wallets && (
        <LoadingContainer>
          <p> Loading wallets...</p>
        </LoadingContainer>
      )}

      {walletIsError && !wallets && (
        <LoadingContainer>
          <p>There was an error getting wallets</p>
          <button onClick={() => refetch()} className="text-red-500">
            Click to retry
          </button>
        </LoadingContainer>
      )}
      {wallets && (
        <WalletGrid>
          {wallets?.map(
            ({
              balance,
              currency,
              number,
            }: {
              balance: string;
              currency: string;
              number: string;
            }) => (
              <WalletContainer>
                <WalletName>{formatAmount(balance)}</WalletName>
                <WalletNumber>{number}</WalletNumber>

                <TypeWrapper>
                  <WalletType>{currency}</WalletType>

                  <CreditCardIcon className="h-6 w-6 text-yellow-800" />
                </TypeWrapper>
              </WalletContainer>
            ),
          )}
        </WalletGrid>
      )}

      {/* 
      <CardsWrapper>
        <Card width={35}>
          <CardTitle>Quick Conversion</CardTitle>

          <CurrencySelectWrapper>
            <div>
              <Label>From</Label>
              <Select>
                <SelectTrigger className="w-[155px]">
                  <SelectValue placeholder="From" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {currencies?.map(({ value }) => (
                      <SelectItem value={value}>{value}</SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <IconWrapper>
              <ArrowRightIcon />
            </IconWrapper>

            <div>
              <Select>
                <Label>To</Label>
                <SelectTrigger className="w-[155px]">
                  <SelectValue placeholder="To" />
                </SelectTrigger>
                <SelectContent>
                  {currencies?.map(({ value }) => (
                    <SelectItem value={value}>{value}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </CurrencySelectWrapper>

          <div className="grid w-full max-w-sm items-center gap-1.5 m-1rem">
            <Label htmlFor="amount">Amount</Label>
            <Input type="text" id="amount" placeholder="Amount" />
          </div>

          <button
            type="submit"
            className="flex w-full justify-center py-2 rounded-md bg-red-500 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-7"
          >
            Convert
          </button>

          <RateContainer>
            {/* <RateContent>Converted rates will appear here.</RateContent> */}
      {/* <div>
              <RateLabel>Rate</RateLabel>
              <RateContent>
                <p>1 GHS ≅ 140.36NGN</p>

                <p>1 NGN ≅ 0.0071 GHS*</p>
              </RateContent>
            </div>

            <div>
              <RateLabel>Expected amount</RateLabel>
              <RateContent>GHS 1.42</RateContent>
            </div>
          </RateContainer>
        </Card> */}

      {/* <Card width={65}>
          <CardTitle>Conversion History</CardTitle>

          <EmptyContainer center="center">
            <Lottie options={animationDefaultOptions} height={150} width={150} />
            <EmptyTitle>You have no transactions yet</EmptyTitle>
            <EmptyTitleDescription>
              Complete transactions to see your records here
            </EmptyTitleDescription>
          </EmptyContainer>
        </Card>
      </CardsWrapper>  */}

      <AddWallets open={openAddWallets} close={handleClose} />
    </Wrapper>
  );
};

const AddWallets = ({ open, close }: { open: boolean; close: () => void }) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className="DialogTitle">Add Wallet</Dialog.Title>
          <Label className="text-gray-500 text-sm">Choose type of wallet to add here</Label>

          <div style={{ height: "12vh", marginTop: "1rem" }}>
            <Select>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Choose wallet" />
              </SelectTrigger>
              <SelectContent className="h-[10]">
                <SelectGroup>
                  {wallets?.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <button
                type="button"
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Save changes
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button onClick={close} className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Wallet;
