import moment from "moment";
import { useState, useEffect } from "react";

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export const Countdown = ({ endTime }: { endTime: string }) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = moment(endTime).diff(moment());
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    const key = interval as keyof TimeLeft;
    if (timeLeft[key] !== undefined) {
      timerComponents.push(
        <span key={interval}>
          {timeLeft[key]} {interval}{" "}
        </span>,
      );
    }
  });

  return <div>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</div>;
};
