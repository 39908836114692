import React, { useState } from "react";
import TradeOrders from "./TradeOrders";
import { Button } from "./ui/button";
import LiveTrades from "./LiveTrades";
import P2PUserTradeOrders from "./P2PUserTradeOrders";

const SUB_NAV_ITEMS = [
  { name: "Live Trade", component: <LiveTrades /> },
  { name: "Orders", component: <TradeOrders /> },
  { name: "My Trade", component: <P2PUserTradeOrders /> },
];

const TradeRequests = () => {
  const [selectedSubNav, setSelectedSubNav] = useState<string>(SUB_NAV_ITEMS[0].name);

  const renderSubNav = SUB_NAV_ITEMS.find((item) => item.name === selectedSubNav)?.component;

  return (
    <div style={{ marginTop: "2em" }}>
      {/* Sub-navigation for P2P Trade */}
      <div style={{ marginBottom: "1rem" }}>
        {SUB_NAV_ITEMS.map(({ name }) => (
          <Button
            key={name}
            onClick={() => setSelectedSubNav(name)}
            style={{
              background: selectedSubNav === name ? "#fff5f0" : "#fff",
              color: selectedSubNav === name ? "#B52C2C" : "#000",
            }}
            className="sub-nav-button"
          >
            {name}
          </Button>
        ))}
      </div>

      {/* Render the selected component */}
      {renderSubNav}
    </div>
  );
};

export default TradeRequests;
