import { BannerWrapper, BannerContent, Continue } from "./Styles";

const Banner = () => {
  return (
    <BannerWrapper>
      <BannerContent>
        Your account activation is pending completion. Could you tell us a bit about yourself to
        proceed? <Continue to="/home/account">Continue</Continue>
      </BannerContent>
    </BannerWrapper>
  );
};

export default Banner;
