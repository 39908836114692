import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useCookie } from "./useCookieStorage";

type UserToken = {
  access?: string;
  refresh?: string;
  status?: boolean;
};

type AuthContextType = {
  user: UserToken | null;
  login: (data: UserToken) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserToken | null>(null);
  const [accessToken, setAccessToken, removeAccessToken] = useCookie("access", "");
  const [refreshToken, setRefreshToken, removeRefreshToken] = useCookie("refresh", "");
  const navigate = useNavigate();

  // Function to decode JWT and get expiration time
  const getTokenExpirationDate = (token: string): Date | null => {
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      if (decoded.exp) {
        return new Date(decoded.exp * 1000);
      }
    } catch (error) {
      console.error("Failed to decode JWT token", error);
    }
    return null;
  };

  // Function to check if token is expired
  const isTokenExpired = (token: string): boolean => {
    const expirationDate = getTokenExpirationDate(token);
    if (expirationDate) {
      return expirationDate < new Date();
    }
    return true;
  };

  // Function to check and logout if token is expired
  const checkTokenExpiration = () => {
    if (accessToken && isTokenExpired(accessToken)) {
      logout();
    }
  };

  useEffect(() => {
    // Initialize user state from cookies or localStorage on component mount
    const storedAccessToken = localStorage.getItem("access") || accessToken;
    const storedRefreshToken = localStorage.getItem("refresh") || refreshToken;

    if (storedAccessToken && storedRefreshToken) {
      setUser({ access: storedAccessToken, refresh: storedRefreshToken });
    }

    // Set an interval to check token expiration
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [accessToken, refreshToken]);

  const login = (data: UserToken) => {
    setUser(data);
    setAccessToken(data.access || "");
    setRefreshToken(data.refresh || "");

    localStorage.setItem("access", data.access || "");
    localStorage.setItem("refresh", data.refresh || "");

    navigate("/home/transfer", { replace: true });
  };

  const logout = () => {
    setUser(null);
    removeAccessToken();
    removeRefreshToken();

    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    return navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
