import { useEffect, useState } from "react";
import {
  useConfirmTradeMutationApi,
  useGetPaymentOptions,
  useP2pOrders,
  useVerifyTradeOTPMutationApi,
} from "src/hooks/useTrading";
import {
  TitleWrapper,
  Title,
  TitleDescription,
  TableContainer,
  TableHeading,
  TableWrapper,
} from "../Styles";
import { Button } from "./ui/button";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "./ui/table";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import { currencies } from "src/helpers/countriesWithCurrencies";
import { getCountries } from "src/services";
import { useQuery } from "@tanstack/react-query";
import moment, { Moment } from "moment";
import TradeOrderDetailsModal from "./TradeOrderDetailsModal";
import ConfirmTradeOTPModal from "./ConfirmTradeOTPModal";
import { useB2bOrders } from "src/hooks/useB2bTrading";
import { getButtonText } from "src/helpers/utils";

interface CountdownProps {
  endTime: string;
}

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const Countdown: React.FC<CountdownProps> = ({ endTime }) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = moment(endTime).diff(moment());
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval) => {
    const key = interval as keyof TimeLeft;
    if (timeLeft[key] !== undefined) {
      timerComponents.push(
        <span key={interval}>
          {timeLeft[key]} {interval}{" "}
        </span>,
      );
    }
  });

  return <div>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</div>;
};

const BusinessTradeOrders = ({ businessId }: { businessId?: any }) => {
  const [page, setPage] = useState<number>(1);
  const [paymentFilter, setPaymentFilter] = useState<any>([]);
  const [countryFilter, setCountryFilter] = useState<any>([]);
  const [currencyFilter, setCurrencyFilter] = useState<any>([]);
  const [openTradeOrdersModal, setOpenTradeOrdersModal] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState<any>({});
  const [showOTPModal, setShowOTPModal] = useState<any>(false);
  // const [tableData, setTableData] = useState<any>([]);

  function handleRowClick(props: any) {
    setOpenTradeOrdersModal(true);
    setSelectedRowItem(props);
  }

  // const {
  //   data: ordersResponse,
  //   isError,
  //   isFetching,
  //   refetch,
  //   isLoading,
  // } = useP2pOrders({
  //   currencyCode: currencyFilter?.value,
  //   paymentMethods: paymentFilter?.slug,
  //   region: countryFilter?.code,
  //   page,
  // });

  const {
    data: businessOrdersResponse,
    isError: isBusinessOrdersError,
    isFetching: isFetchingBusinessOrders,
    refetch: refetchBusinessOrders,
    isLoading: isLoadingBusinessOrders,
  } = useB2bOrders({
    businessId: businessId,
    currencyCode: currencyFilter?.value,
    paymentMethods: paymentFilter?.slug,
    region: countryFilter?.code,
    page,
  });

  const isBusiness = Boolean(businessId);

  const businessOrders = businessOrdersResponse?.results || [];

  const tableData = businessOrders;

  const { data: paymentOptionsResponse } = useGetPaymentOptions();
  const paymentOptions = paymentOptionsResponse || [];

  const { data: countries, isLoading: countriesIsLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: () => getCountries(),
  });

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    refetchBusinessOrders();
  }, [businessId]);

  useEffect(() => {
    refetchBusinessOrders();
  }, [countryFilter, currencyFilter, paymentFilter, page]);

  const isFetchingData = isFetchingBusinessOrders;
  const isLoadingData = isLoadingBusinessOrders;
  const isErrorState = isBusinessOrdersError;

  return (
    <div style={{ marginTop: "2em" }}>
      <TableContainer>
        <TableHeading>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mr-3">
              <ReactSelect
                onChange={(option: any) => setPaymentFilter(option)}
                options={paymentOptions}
                isClearable={true}
                placeholder="Payment"
                isDisabled={countriesIsLoading}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.slug}
                value={paymentFilter}
              />
            </div>
            <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mr-3">
              <ReactSelect
                options={currencies}
                placeholder="Currency"
                formatOptionLabel={(option) => `${option.value} - ${option.label}`}
                isClearable
                isSearchable
                isDisabled={isFetchingData}
                onChange={(option) => {
                  setCurrencyFilter(option);
                }}
                value={currencyFilter}
              />
            </div>

            <div className="grid w-[250px] max-w-sm items-center gap-1.5 m-1rem">
              <ReactSelect
                onChange={(option: any) => setCountryFilter(option)}
                options={countries}
                isClearable={true}
                placeholder="Region"
                isDisabled={isFetchingData}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.code}
                value={countryFilter}
              />
            </div>
          </div>
        </TableHeading>
        <TableWrapper height="calc(100vh - 450px)">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Amount</TableHead>
                <TableHead>Lumi Amount</TableHead>
                <TableHead>Conversion rate</TableHead>
                <TableHead>Currency</TableHead>
                <TableHead>Payment method</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Timer</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isFetchingData ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : isErrorState ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Error fetching data
                  </TableCell>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    <button onClick={() => refetchBusinessOrders()}>Refetch</button>
                  </TableCell>
                </TableRow>
              ) : tableData.length === 0 ? (
                <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                  Ooops, there are no items at the moment.
                </TableCell>
              ) : (
                tableData.map((order: any) => {
                  return (
                    <TableRow
                      style={{ fontSize: "0.85rem", color: "#707070" }}
                      key={uuidv4()}
                      onClick={() => {
                        handleRowClick(order);
                      }}
                    >
                      <TableCell>{order.amount}</TableCell>
                      <TableCell>{Number(order.amount_in_lumi).toFixed(2)}</TableCell>
                      <TableCell>{`${order.currency_code} ${order.conversion_rate_usd}`}</TableCell>
                      <TableCell>{order.currency_code}</TableCell>
                      <TableCell>{order.payment_method?.payment_option?.name || "N/A"}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>
                        {order.status === "Pending" ? (
                          <Countdown endTime={order.expires_at} />
                        ) : (
                          "Time's up!"
                        )}
                      </TableCell>
                      {order?.status === "Pending" && (
                        <TableCell className="text-right">
                          <Button
                            onClick={() => setOpenTradeOrdersModal(true)}
                            disabled={
                              isLoadingData ||
                              !order?.is_accepted ||
                              (order?.trade_role === "SELLER" &&
                                order?.confirmed_by_buyer == false) ||
                              (order?.trade_role === "BUYER" && order?.confirmed_by_buyer === true)
                            }
                            style={{ background: "#fff5f0", color: "#B52C2C" }}
                            className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
                          >
                            {isLoadingData ? "Loading..." : getButtonText(order)}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableWrapper>

        <TradeOrderDetailsModal
          open={openTradeOrdersModal}
          handleClose={() => {
            setOpenTradeOrdersModal(false);
          }}
          isBusiness={Boolean(businessId)}
          details={selectedRowItem}
          businessId={businessId}
        />
        <ConfirmTradeOTPModal
          open={showOTPModal}
          handleClose={() => setShowOTPModal(false)}
          id={selectedRowItem?.id}
          businessId={businessId}
        />
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={handlePrevPage}
            disabled={page === 1 || !businessOrdersResponse?.previous}
            className={`btn ${
              page === 1 || !businessOrdersResponse?.previous ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {"<<  Previous"}
          </button>
          <span className="mx-4">Page {page}</span>
          <button
            onClick={handleNextPage}
            disabled={!businessOrdersResponse?.next}
            className={`btn ${
              !businessOrdersResponse?.next ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {"Next >>"}
          </button>
        </div>
      </TableContainer>
      {/* {showConfirmReceipt && (
        <VerifyTrade open={showConfirmReceipt} handleClose={() => setShowConfirmReceipt(false)} />
      )} */}
    </div>
  );
};

export default BusinessTradeOrders;
