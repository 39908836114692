import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useToast } from "src/components/common/ui/use-toast";
import {
  acceptB2bTradeOrder,
  confirmBusinesTradeOrder,
  createBusinessTradeOrder,
  createBusinessTradeRequest,
  deleteb2b2pTradeRequest,
  editBusinessTradeRequest,
  getB2bLiveRequests,
  getB2bOrders,
  getBusinessTrades,
} from "src/services";

export function useB2bLiveTrades({
  businessId,
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  businessId: string;
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  return useQuery({
    queryKey: ["business-live-requests"],
    queryFn: () =>
      getB2bLiveRequests({ businessId, tradeType, currencyCode, paymentMethods, region, page }),
    refetchOnWindowFocus: false,
    enabled: !!businessId,
  });
}

export function useB2bTrade({
  businessId,
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  businessId: string;
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  return useQuery({
    queryKey: ["business-trades"],
    queryFn: () =>
      getBusinessTrades({ businessId, tradeType, currencyCode, paymentMethods, region, page }),
    refetchOnWindowFocus: false,
    enabled: !!businessId,
  });
}

export function useB2bOrders({
  businessId,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  businessId: string;
  currencyCode?: any;
  paymentMethods?: string;
  region?: string;
  page?: number;
}) {
  return useQuery({
    queryKey: ["business-orders"],
    queryFn: () => getB2bOrders({ businessId, currencyCode, paymentMethods, region, page }),
    refetchOnWindowFocus: false,
    enabled: !!businessId,
  });
}

export function useCreateBusinessTradeRequestMutationApi() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const createBusinessRequest = useMutation({
    mutationKey: ["createTradeRequest"],
    mutationFn: (payload: any) => createBusinessTradeRequest(payload),
    onSuccess: () => {
      toast({
        description: "Trade request successfully created",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });

  const editBusinessRequest = useMutation({
    mutationKey: ["editTradeRequest"],
    mutationFn: ({ requestId, payload }: { requestId: string; payload: any }) =>
      editBusinessTradeRequest(requestId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["business-trades"] });
      toast({
        description: "Trade request successfully edited",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });

  const deleteBusinessRequest = useMutation({
    mutationKey: ["deleteTradeRequest"],
    mutationFn: (id: string) => deleteb2b2pTradeRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["business-trades"] });
      toast({
        description: "Trade request successfully deleted",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.detail || "An error occured",
      });
    },
  });

  return {
    createBusinessRequest,
    editBusinessRequest,
    deleteBusinessRequest,
  };
}

export function useCreateBusinessOrderMutationApi() {
  const { toast } = useToast();

  return useMutation({
    mutationKey: ["convertCurrency"],
    mutationFn: (payload: any) => createBusinessTradeOrder(payload),
    onSuccess: () => {
      toast({
        description: "Order successfully created",
      });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });
}

export function useConfirmBusinessTradeMutationApi() {
  const [businessConfirmTradeResponse, setBusinessConfirmTradeResponse] = useState<any>({});
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const confirmB2bTrade = useMutation({
    mutationKey: ["confirmBusinessTrade"],
    mutationFn: ({ orderId, businessId }: { orderId: string; businessId: string }) =>
      confirmBusinesTradeOrder(orderId, businessId),
    onSuccess: (res: any) => {
      setBusinessConfirmTradeResponse(res);
      toast({
        description: res?.message,
      });
      queryClient.invalidateQueries({ queryKey: ["business-trades"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.response?.data?.message || "An error occured",
      });
    },
  });
  return { businessConfirmTradeResponse, confirmB2bTrade };
}

export function useAcceptB2bTradeMutation() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["acceptTrade"],
    mutationFn: (payload: { order_id: string; accept: boolean; business_id: string }) =>
      acceptB2bTradeOrder(payload),
    onSuccess: (res) => {
      toast({
        description: res?.message || "Request successful.",
      });
      queryClient.invalidateQueries({ queryKey: ["trading-requests"] });
    },
    onError: (e: any) => {
      toast({
        description: e?.message || "An error occured",
      });
    },
  });
}
