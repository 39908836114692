import * as Dialog from "@radix-ui/react-dialog";

import styled from "styled-components";
import { Button } from "./ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Countdown } from "./CountDown";
import LoadingIndicator from "./LoadingIndicator";
import { useEffect, useState } from "react";
import {
  useConfirmTradeMutationApi,
  useAcceptTradeMutation,
  useP2pOrders,
} from "src/hooks/useTrading";
import ConfirmTradeOTPModal from "./ConfirmTradeOTPModal";
import {
  useAcceptB2bTradeMutation,
  useB2bOrders,
  useConfirmBusinessTradeMutationApi,
} from "src/hooks/useB2bTrading";

const TradeOrderDetailsModal = ({
  open,
  handleClose,
  details,
  isBusiness,
  businessId,
}: {
  open: boolean;
  handleClose: () => void;
  details: any;
  isBusiness?: boolean;
  businessId?: any;
}) => {
  const getButtonText = (order: {
    trade_role: string;
    confirmed_by_buyer: boolean;
    confirmed_by_seller: boolean;
    status: string;
    is_initiator: boolean;
    is_accepted: boolean;
  }) => {
    if (order?.status === "Pending" && !order?.is_accepted && !order?.is_initiator) {
      return "Accept Order";
    } else if (order?.status === "Pending" && !order?.is_accepted && order?.is_initiator) {
      return "Awaiting Confirmation";
    }

    if (order?.trade_role === "BUYER") {
      if (!order?.confirmed_by_buyer) {
        return "Transferred, Notify Seller";
      }
      if (!order?.confirmed_by_seller) {
        return "Awaiting Seller Confirmation";
      }
    }
    if (order?.trade_role === "SELLER" && !order?.confirmed_by_buyer) {
      return "Awaiting Buyer Confirmation";
    } else if (order?.trade_role === "SELLER" && order?.confirmed_by_buyer) {
      return "Received, Release AKL";
    }
    return "Confirm";
  };

  const { mutationResult } = useConfirmTradeMutationApi();
  const { confirmB2bTrade } = useConfirmBusinessTradeMutationApi();
  const { accepTradeMutationResult } = useAcceptTradeMutation();
  const acceptB2bTrade = useAcceptB2bTradeMutation();
  const { refetch } = useP2pOrders({});
  const { refetch: refetchBusinessOrders } = useB2bOrders({
    businessId: businessId,
  });

  const [showOTPModal, setShowOTPModal] = useState<any>(false);

  function handleConfirmTrade(id: string) {
    const mutate = mutationResult;
    if (isBusiness) {
      confirmB2bTrade.mutate({ orderId: id, businessId: businessId });
    } else {
      mutate.mutate(id);
    }
  }

  // Accept or Decline Trade
  const handleAcceptTrade = (id: string, accept: boolean) => {
    const payload = {
      order_id: id,
      accept,
    };
    if (isBusiness) {
      acceptB2bTrade.mutate({
        ...payload,
        business_id: businessId,
      });
    } else {
      const mutate = accepTradeMutationResult;
      mutate.mutate(payload);
    }
    handleClose();
  };

  useEffect(() => {
    if (mutationResult.isSuccess) {
      refetch();
      handleClose();
      if (mutationResult.data?.verification_required) {
        setShowOTPModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationResult.isSuccess]);

  useEffect(() => {
    if (confirmB2bTrade.isSuccess) {
      refetchBusinessOrders();
      handleClose();
      if (confirmB2bTrade.data?.verification_required) {
        setShowOTPModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmB2bTrade.isSuccess]);

  useEffect(() => {
    if (accepTradeMutationResult.isSuccess) {
      refetch();
      handleClose();
    }
  }, [accepTradeMutationResult.isSuccess]);

  useEffect(() => {
    if (acceptB2bTrade.isSuccess) {
      refetchBusinessOrders();
      handleClose();
    }
  }, [acceptB2bTrade.isSuccess]);

  return (
    <>
      <ConfirmTradeOTPModal
        open={showOTPModal}
        handleClose={() => setShowOTPModal(false)}
        id={details?.id}
        businessId={businessId}
      />
      <Dialog.Root open={open}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <DialogContent className="DialogContentSmall">
            <Dialog.Title className="DialogTitle">Trade Order Details</Dialog.Title>
            {mutationResult.isPending ? (
              <div className="flex items-center justify-center min-h-80">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <div style={{ display: "flex", flexDirection: "column", marginTop: 25 }}>
                  <Row>
                    <Item>Amount</Item>
                    <Item>{details?.amount}</Item>
                  </Row>

                  <Row>
                    <Item>Lumi amount</Item>
                    <Item>{details?.amount_in_lumi}</Item>
                  </Row>

                  <Row>
                    <Item>Convertion Rate</Item>
                    <Item>{details?.conversion_rate_usd}</Item>
                  </Row>

                  <Row>
                    <Item>Currency</Item>
                    <Item>{details?.currency_code}</Item>
                  </Row>

                  <Row>
                    <Item>Payment method</Item>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "250px",
                      }}
                    >
                      <Item>{details?.payment_method?.payment_option.name}</Item>
                      <Item>{details?.payment_method?.account_details.provider_name}</Item>
                      <Item>{details?.payment_method?.account_details.account_number}</Item>
                      <Item>{details?.payment_method?.account_details.name}</Item>
                    </div>
                  </Row>

                  <Row>
                    <Item>Status</Item>
                    <Item>{details?.status}</Item>
                  </Row>

                  <Row>
                    <Item>Payment confirmed by</Item>
                    <Item>{details?.trade_role === "BUYER" ? "Buyer" : "Seller"}</Item>
                  </Row>
                  <Row>
                    <Item>Note</Item>
                    <Item>{details?.note}</Item>
                  </Row>

                  <Row>
                    <Item>Timer</Item>
                    {details.status === "Pending" ? (
                      <Countdown endTime={details.expires_at} />
                    ) : (
                      "Time's up!"
                    )}
                  </Row>
                </div>
                {details?.status === "Pending" &&
                !details?.is_accepted &&
                !details?.is_initiator ? (
                  <Footer>
                    <StyledButton
                      onClick={() => handleAcceptTrade(details?.id, true)}
                      type="submit"
                      disabled={mutationResult.isPending}
                      className="flex items-center justify-center py-2 mr-3 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                    >
                      Accept
                    </StyledButton>
                    <Dialog.Close asChild>
                      <StyledButton
                        onClick={() => handleAcceptTrade(details?.id, false)}
                        type="submit"
                        disabled={mutationResult.isPending}
                        className="flex items-center justify-center py-2 rounded-md bg-gray-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      >
                        Decline
                      </StyledButton>
                    </Dialog.Close>
                  </Footer>
                ) : details?.status === "Pending" ? (
                  <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
                    <Dialog.Close asChild>
                      <StyledButton
                        onClick={() => handleConfirmTrade(details?.id)}
                        type="submit"
                        disabled={
                          !details?.is_accepted ||
                          (details?.trade_role === "SELLER" &&
                            details?.confirmed_by_buyer === false) ||
                          (details?.trade_role === "BUYER" && details?.confirmed_by_buyer === true)
                        }
                        className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      >
                        {getButtonText(details)}
                      </StyledButton>
                    </Dialog.Close>
                  </div>
                ) : null}
              </>
            )}
            <Dialog.Close asChild>
              <CloseIconButton className="IconButton" aria-label="Close" onClick={handleClose}>
                <Cross2Icon width="20" height="20" />
              </CloseIconButton>
            </Dialog.Close>
          </DialogContent>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

const CloseIconButton = styled.button`
  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const DialogContent = styled(Dialog.Content)`
  @media print {
    width: 100vw;
    heigt: 100vh;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 0.5rem;
`;

const Item = styled.div`
  width: 50%;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
`;
export default TradeOrderDetailsModal;
