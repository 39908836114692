import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import FileUpload from "../FileUpload";
import { Button } from "./ui/button";
import { DropzoneContainer } from "./Styles";

interface DropzoneProps {
  onFileUpload: (formData: FormData) => void;
  isLoading?: boolean;
  isFinished?: boolean;
  fileTypes?: any;
  id: string;
}

const Dropzone: React.FC<DropzoneProps> = ({
  onFileUpload,
  isLoading,
  isFinished,
  fileTypes = "",
  id,
}) => {
  const [file, setFile] = useState<any>(null);

  const onDrop = useCallback((acceptedFile: any) => {
    setFile(acceptedFile[0]);
  }, []);

  useEffect(() => {
    if (isFinished) setFile(null);
  }, [isFinished]);

  const handleFileUpload = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append(id, file);
    onFileUpload(formData);
  };

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, accept: fileTypes });

  return (
    <div {...getRootProps({ onClick: (e) => e.stopPropagation() })} style={dropzoneStyles}>
      <input {...getInputProps()} />
      {!Boolean(file) && (
        <DropzoneContainer>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p>Drop the files here ...</p>
            <button
              type="submit"
              onClick={open}
              className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
            >
              Open
            </button>
          </div>
        </DropzoneContainer>
      )}

      {file && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <FileUpload file={file} onCloseClick={() => setFile(null)} isLoading={isLoading} />

          <Button
            onClick={handleFileUpload}
            disabled={isLoading}
            className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
          >
            {isLoading ? "loading..." : "Upload"}
          </Button>
        </div>
      )}
    </div>
  );
};

const dropzoneStyles: React.CSSProperties = {
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default Dropzone;
