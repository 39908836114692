import { useState, useEffect } from "react";

interface ValidationFunction {
  (values: any): any;
}

const useForm = (validate?: ValidationFunction) => {
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    validate && setErrors(validate(values));
  }, [values, validate]);

  const reset = () => {
    setValues({});
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const { name, value } = event?.target;

    const formattedValue = name === "receiver_wallet_number" ? value.replace(/\D/g, "") : value;

    setValues({
      ...values,
      [name]: formattedValue,
    });
  };

  return {
    handleChange,
    values,
    errors,
    reset,
    setValues,
  };
};

export default useForm;
